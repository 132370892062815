import React from 'react';

import Grid from '@material-ui/core/Grid';

import "./style.scss"

import { Link } from "react-router-dom";

const DeviceCard = ({ device }) => {

	const last_updates = device.channels.map(c => {
		const timestamp = new Date(c.last_value_timestamp + 'Z');
		return (
		<div className="row" key={c.id}>
			<p className="last-seen">{c.alias || c.name}: {c.last_value} {c.unit}</p>
			<p className="last-seen">{timestamp.toLocaleString("en-US", {timeZone: "UTC"})}</p>
		</div>
		)
	})

	const deviceStatus = <div className={`status-indicator ${ device.active ? "active" : "inactive" }`}>&nbsp;</div>;

	return(
		<Grid item xs={12} md={6} lg={4} xl={3}>
			<div className="device-card">
				<div className="device-card-content">
					<div className="device-card-header">
						<div className="device-number">{device.serial_number}</div>
						<div className="device-type">{device.type}</div>
						{deviceStatus}
					</div>
					<div className="device-card-body">
						<div className="left">
							<p className="label">{device.alias ? device.alias : `Device ${device.serial_number}`}</p>
						</div>
						<div className="right">
							alerts <span>{device.active_alerts}</span>
						</div>
					</div>
					<div className="device-card-text">
						{last_updates}
					</div>
				</div>

				<div className="device-details">
					<Link to={`/devices/${device.id}/data`}>View Details</Link>
				</div>
			</div>
		</Grid>
	)
}

export default DeviceCard;
