import React, { useState, useEffect } from 'react';
import "./style.scss"
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { AlertView } from "../AlertView/index"
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import apiService from "../../services/request";

import { Switch, FormControlLabel, FormGroup } from '@material-ui/core';

const DeviceAlerts = ({ handleOpenConfig,
						  deviceData,
						  handleNotificationAck,
						  deleteNotification,
						  user,
						  handleAlertsUpdate,
						  handleAlertsDelete,
						  handleAlertCreate }) => {

	const [switchOn, setSwitchOn] = useState(true);
	const [switchLabel, setSwitchLabel] = useState("Alarmas activas");
	const [active, setActive] = useState({});
	const [alerts, setAlerts] = useState([]);
	const [recipients, setRecipients] = useState([]);
	const [recipientFilter, setRecipientFilter] = useState("");

	useEffect(() => {
		if (deviceData) {
			setAlerts(deviceData["alerts"])
		}
	}, [deviceData])

	useEffect(() => {
		if (deviceData) {
			let recipients = new Set(deviceData["alerts"].map(a => a.recipients).flat())
			setRecipients([...recipients])

			if (deviceData.active_alarms === false) {
				setSwitchOn(false);
				setSwitchLabel("Alarmas desactivadas");
			} else {
				setSwitchOn(true);
				setSwitchLabel("Alarmas activas");
			}
		}
	}, [deviceData])

	useEffect(() => {
		if (recipientFilter) {
			if (recipientFilter === "reset") {
				setAlerts(deviceData["alerts"])
			} else {
				let alert_filter = []
				deviceData["alerts"].forEach(a => {
					if (a.recipients.find(r => r === recipientFilter)) {
						alert_filter.push(a)
					}
				})
				setAlerts(alert_filter);
			}
		}
	}, [recipientFilter, deviceData])

	if (!deviceData) return (<h1>loading...</h1>);
	const middleNotificationAck = async (id) => {
		let active = await handleNotificationAck(id)
		setActive(active)
	}

	const middleNotificationDelete = async (id) => {
		let active = await deleteNotification(id)
		setActive(active)
	}

	const handleSwitchChange = async (event, deviceData) => {
		setSwitchOn(event.target.checked);

		console.log('event, deviceData', event.target.checked, deviceData)

		deviceData.active_alarms = event.target.checked;

		await handleDeviceDataUpdate(deviceData);

		if (!switchOn) {
			setSwitchLabel("Alarmas activas");
		} else {
			setSwitchLabel("Alarmas desactivadas");
		}
	};

	const handleDeviceDataUpdate = async (deviceData) => {
		try {
			const newDevice = await apiService.editDevice(deviceData.id, deviceData)

			console.log('handleDeviceDataUpdate: newDevice', newDevice)
		} catch(e) {
			console.log('handleDeviceDataUpdate e', e)
		}
	}

	const AlertSwitch = ({text}) => {
		return (
			<FormGroup>
				<FormControlLabel control={
					<Switch
						checked={switchOn}
						onChange={(e) => handleSwitchChange(e, deviceData)}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				} label={text} />
			</FormGroup>
		)
	}

	return(
		<main className="main-section alert-section">
			<div className="alert-header">
				<h2>Alerts</h2>
			</div>

			<div className="buttons btn-container">
				{
					active.id === undefined && (user.role === "Master Admin" || user.role === "Client Admin")
					&& <AlertSwitch text={switchLabel} />
				}

				{
					active.id === undefined && <button className="main-btn" onClick={() => setActive({
						'id':"new",
						'name': '',
						'channel_id': 1,
						'set_value': '',
						'recipients': [],
						'active': [],
						'notifications': [],
						'range_value': false,
						'above_set_value': false
					})}>
						New
					</button>
				}

				{
					active.id && <button className="main-btn" onClick={() => setActive({})} > Go Back</button>
				}
			</div>

			<div>
				<label htmlFor="role-select">Recipient:</label>
				<Select
					labelId="role-select"
					value={recipientFilter}
					className="select-role"
					onChange={(e) => setRecipientFilter(e.target.value)}
				>
					{recipients.map(r => <MenuItem value={r} key={r}>{r}</MenuItem>)}
				</Select>
				{recipientFilter && recipientFilter !== "reset" && <button onClick={() => setRecipientFilter("reset")}>Reset</button>}
			</div>

			{
				active.id ? <AlertView alert={active}
															 user={user}
															 deviceData={deviceData}
															 channels={deviceData['channels']}
															 setActive={setActive}
															 handleAlertsUpdate={handleAlertsUpdate}
															 handleAlertsDelete={handleAlertsDelete}
															 handleAlertCreate={handleAlertCreate}/>
					: <AlertTable alerts={alerts} setActive={setActive} active={active}/>
			}
			{ active.id &&
				<div className="alerts">
					<h2>{active.notifications.length === 0 ? "No active alerts" :"Active alerts"}</h2>
					{active.notifications.map(n => <Notification notification={n}
														  active={active}
														  key={n.id}
														  deleteNotification={middleNotificationDelete}
														  handleNotificationAck={middleNotificationAck}/>)}
				</div>
			}
			{
				user.role === "Master Admin" && active.id === undefined ?
					<div className="buttons btn-container">
					<button className="main-btn" onClick={() => handleOpenConfig(2)} > Device Config</button>
					</div>
					: ""
			}
		</main>
	)
}

const AlertTable = ({alerts, setActive, active}) => {

	return (
		<div className="alerts-set-table">
			<TableContainer component={Paper} className="clients-list table-container">
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Alert Name</TableCell>
							<TableCell>Channels</TableCell>
							<TableCell>Value</TableCell>
							<TableCell>Recipients</TableCell>
							<TableCell>Active</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{alerts.map(a => {
							return (
								<TableRow key={a.id} onClick={()=>{setActive(a)}} className={a.id === active.id ? "active" : ""}>
									<TableCell>{a.name}</TableCell>
									<TableCell>{a.channels.join(", ")}</TableCell>
									<TableCell>{a.range_value ? `${a.min_value} - ${a.max_value}`: a.set_value}</TableCell>
									<TableCell>{a.recipients.length}</TableCell>
									<TableCell>{a.active ? "yes" : "no"}</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	)
}

const Notification = ({ notification, handleNotificationAck, deleteNotification }) => {
	return (
		<div className="alert-item" key={notification.id}>
			<span className="alt-detail">Activation date:{new Date(notification.timestamp +'Z').toLocaleString()}</span>
			<span className="alt-detail">{notification.message}</span>
			<div className="btn-container">
				<button className="small-btn btn-danger" onClick={() => deleteNotification(notification.id)}>Delete</button>
			</div>
		</div>
	)
}

export default DeviceAlerts;
