import React, {useState} from 'react';
import { Redirect } from 'react-router-dom';
import logo from "../../assets/images/ffqlogo.png";
import './style.scss';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const LoginView = ({ handleLogin, user }) => {
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState('');
    const [open, setOpen] = useState(true);

    if (user) {
        return <Redirect to="/devices" />
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        if (mail && password) {
            let status = await handleLogin({mail, password})
            setMail('')
            setPassword('')
            if (status && status.error) {setErrors(status.error)}
        }
    }

    return (
        <main className="main-login">
            <section className="login-container">
                <div className="logo">
                    <img src={logo} alt="" className="img-logo"/>
                </div>
                <div className="error-text">{errors}</div>
                <div>
                    Mail:
                    <input type='mail'
                           value={mail}
                           onChange={e => setMail(e.target.value)}
                    />
                </div>
                <div>
                    Password:
                    <input type='password'
                           value={password}
                           onChange={e => setPassword(e.target.value)}
                    />
                </div>
                <div className="btn-container">
                    <button className="main-btn" onClick={handleSubmit}>login</button>
                </div>
            </section>
        </main>
    )
}

export default LoginView;
