import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import React, {useEffect, useState} from "react";
import apiService from "../../services/request";
import {Checkbox, ListItemText} from "@material-ui/core";
import Input from '@material-ui/core/Input';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AccessTable = ({ user }) => {
    const [users, setUsers] = useState([])
    const [activeUser, setActiveUser] = useState({});
    const [roles, setRoles] = useState({});
    const [clients, setClients] = useState({});

    useEffect(() => {
        try {
            if (user.role === "Master Admin" || user.role === "Client Admin") {
                apiService.getUsers().then(users => setUsers(users));
            } else {
                apiService.getUsers().then(users => {
                    const filteredUsers = users.filter(u => u.mail === user.mail);
                    setUsers(filteredUsers);
                });
            }
        } catch(e) {
            console.log(e)
        }
    }, [user])

    useEffect(() => {
        try {
            if (user.role === "Master Admin") {
                apiService.getRoles().then(roles => { setRoles(roles) });
            } else if (user.role === "Client Admin") {
                apiService.getRoles().then(roles => { setRoles(roles.filter(role => role.id === 3)) });
            } else {
                setRoles([]);
            }
        } catch(e) {
            console.log(e)
        }
    }, [])

    useEffect(() => {
        try {
            apiService.getClients().then(clients => setClients(clients))
        } catch(e) {
            console.log(e)
        }
    }, [])

    const createUser = async (user) => {
        try {
            let new_user = await apiService.createUser(user);
            setUsers(users.concat(new_user))
            setActiveUser({})
        } catch(e) {
            console.log(e)
        }
    }

    const editUser = async (user) => {
        try {
            let editedUser = await apiService.editUser(user);
            setUsers(users.map(u => u.id === editedUser.id ? editedUser : u))
            setActiveUser({})
        } catch(e) {
            console.log(e)
        }
    }

    const deleteUser = async (user) => {
        try {
            let deletedUser = await apiService.deleteUser(user);
            setUsers(users.filter(u => u.id !== Number(deletedUser.id)))
            setActiveUser({})
        } catch(e) {
            console.log(e)
        }
    }

    return (
        <div className="account-table-container">
            <TableContainer component={Paper} className="table-container">
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Mail</TableCell>
                            <TableCell>Account Type</TableCell>
                            <TableCell>Active</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(user => (
                            <TableRow key={user.id} onClick={()=>{setActiveUser(user)}} className={user.id === activeUser.id ? "active" : ""}>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user.last_name}</TableCell>
                                <TableCell>{user.mail}</TableCell>
                                <TableCell>{user.role}</TableCell>
                                <TableCell>{user.active ? 'Active' : 'Inactive'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="buttons">
                {
                    user.role !== "Client" && <button onClick={() => {setActiveUser({'id':'new'})}}
                                                      className="main-btn">New Account</button>
                }
            </div>
            {activeUser.id && <UserForm setActiveUser={setActiveUser}
                                        handleCreateUser={createUser}
                                        handleEditUser={editUser}
                                        handleDeleteUser={deleteUser}
                                        roles={roles}
                                        clients={clients}
                                        logedUser={user}
                                        user={activeUser}/>
            }
        </div>
    )
}

const UserForm = ({ user, setActiveUser, handleCreateUser, handleEditUser, handleDeleteUser, roles, clients, logedUser }) => {
    const [name, setName] = useState('');
    const [last_name, setLastName] = useState('');
    const [mail, setMail] = useState('');
    const [active, setActive] = useState(true);
    const [errors, setErrors] = useState({});
    const [role, setRole] = useState('');
    const [client, setClient] = useState([]);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
        setName(user.name || '');
        setLastName(user.last_name || '');
        setMail(user.mail || '');
        setActive(user.active || true);
        setRole(user.role || '')
        setClient(user.clients || []);
    }, [user])

    const validateCreateUser = () => {
        if (validateForm()) {return}
        handleCreateUser({name,last_name, mail, role, client, password})
    }

    const validateEditUser = () => {
        if (validateForm()) {return}
        handleEditUser({...user, name, last_name, mail, active, role, client, password})
    }

    const handleSetRole = (new_role) => {
        setRole(new_role);
        if (new_role === "Master Admin") {
            setClient([])
        }
    }

    const validateForm = () => {
        let errors = {}
        const regexValidation = /(^(?=.*\d)(?=.*[a-zA-Z]).{8,}$)/;
        if (!name) {errors.name = 'This field is requierd'}
        if (!last_name) {errors.last_name = 'This field is requierd'}
        if (!mail) {errors.mail = 'This field is requierd'}
        if (role !== "Master Admin" && !client) {errors.client = 'This field is requierd'}
        if (password !== confirmPassword) {errors.password = 'The password does not match!'}
        if (password && !regexValidation.test(password)) {
            errors.password = "Password must contain at least one number, one letter and have at least 8 characters"
        }
        setErrors(errors)
        if (Object.keys(errors).length > 0) {return true}
    }

    const disabledFields = logedUser.role === "Client";
    const disabledRoleField = logedUser.role === "Client" || logedUser.mail === user.mail;

    return (
        <div className="dialog-wrapp">
            <div className="dialog-container">
                <div className="section-header">
                    <div className="title">
                        <h2>User: {user.name}</h2>
                    </div>
                </div>
                <span>{errors.name}</span>
                <div className="type-select">
                    <span className="date-header-label">Name:</span>
                    <input type="text" className="textfield" value={name} onChange={e => setName(e.target.value)} disabled={disabledFields}/>
                </div>
                <span>{errors.last_name}</span>
                <div className="type-select">
                    <span className="date-header-label">Last name:</span>
                    <input type="text" className="textfield" value={last_name} onChange={e => setLastName(e.target.value)} disabled={disabledFields}/>
                </div>
                <span>{errors.mail}</span>
                <div className="type-select">
                    <span className="date-header-label">email:</span>
                    <input type="text" className="textfield" value={mail} onChange={e => setMail(e.target.value)} disabled={disabledFields}/>
                </div>
                <div className="type-select">
                    <span className="date-header-label">Active:</span>
                    <input type="checkbox" className="textfield" checked={active} onChange={() => setActive(!active)} disabled={disabledRoleField}/>
                </div>
                <div className="type-select">
                    <span className="date-header-label">Role</span>
                    <Select
                        labelId="role-select"
                        value={role}
                        className="select-role"
                        disabled={disabledRoleField}
                        onChange={(e) => handleSetRole(e.target.value)}
                    >
                        {roles.map(r => <MenuItem value={r.name} key={r.id}>{r.name}</MenuItem>)}
                    </Select>
                </div>
                <span>{errors.client}</span>
                {
                    role !== "Master Admin" ?
                    <div className="type-select">
                        <span className="date-header-label">Clients</span>
                        <Select
                            labelId="role-select"
                            multiple
                            className="select-role"
                            value={client}
                            input={<Input />}
                            renderValue={(selected)=>selected.join(', ')}
                            onChange={(e) => setClient(e.target.value)}
                            disabled={disabledRoleField}
                            MenuProps={MenuProps}
                        >
                            {clients.map(c =>
                                <MenuItem value={c.name} key={c.name}>
                                    <Checkbox checked={client.indexOf(c.name) > -1} />
                                    <ListItemText primary={c.name} />
                                </MenuItem>
                            )}
                        </Select>
                    </div>: ""
                }
                <div className="type-select">
                    <span className="date-header-label">new password:</span>
                    <input type="password" className="textfield" value={password} onChange={e => setPassword(e.target.value)}/>
                </div>
                <span>{errors.password}</span>
                <div className="type-select">
                    <span className="date-header-label">confirm new password:</span>
                    <input type="password" className="textfield" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}/>
                </div>

                <div className="buttons">
                    <button className="main-btn" onClick={() => setActiveUser({})}>Cancel</button>
                    {
                        user.id === 'new' ?
                            <button className="main-btn"
                                    onClick={validateCreateUser}>
                                Create
                            </button>:
                          <>
                              <button className="main-btn"
                                      onClick={validateEditUser}>Save</button>
                              {
                                  (logedUser.role === "Master Admin" || logedUser.role === "Client Admin") && (logedUser.mail !== user.mail) ?
                                  <button className="main-btn" onClick={() => handleDeleteUser(user)}>Delete</button>:
                                  ""
                              }
                          </>
                    }
                </div>
            </div>
        </div>
    )
}

export default AccessTable;
