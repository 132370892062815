import axios from 'axios'
const baseUrl = '/api/tokens'

const login = async credentials => {
  const response = await axios.post(baseUrl,{}, {
    auth: {
      username: credentials.mail,
      password: credentials.password
    }
  })
  return response.data
}

const logout = (user) => {
  const config = {
    headers: {Authorization: user.token}
  }
  const request = axios.delete(baseUrl, config)
  return request.then(resp => resp.data)
}

export default { login, logout }