import TableContainer from "@material-ui/core/TableContainer";
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import dateIcon from "../../assets/images/calendar.svg";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import apiService from '../../services/request'
import {Link} from "react-router-dom";

const AccountTemporalAccess = ({ devices }) => {
    const [accessData, setAccessData] = useState([])
    const [activeToken, setActiveToken] = useState({});

    useEffect(() => {
        apiService.getTemporalAccess().then(data => setAccessData(data))
    }, [])

    const createToken = async (token) => {
        try {
            let access = await apiService.createTemporalAccess(token)
            setAccessData(accessData.concat(access))
            setActiveToken({});
        } catch(e) {
            console.log(e)
        }
    }

    const editToken = async (token) => {
        try {
            let editedToken = await apiService.editTemporalAccess(token)
            setAccessData(accessData.map(t => t.id === editedToken.id ? editedToken : t))
            setActiveToken({});
        } catch(e) {
            console.log(e)
        }
    }

    const deleteToken = async (token_id) => {
        try {
            let deletedToken = await apiService.deleteTemporalAccess(token_id)
            setAccessData(accessData.filter(t => t.id !== Number(deletedToken.id)))
            setActiveToken({})
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="account-table-container">
            <TableContainer component={Paper} className="table-container">
                <Table stickyHeader aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Device</TableCell>
                            <TableCell>Channels</TableCell>
                            <TableCell>Expiration Date</TableCell>
                            <TableCell>Link</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accessData.map(t => (
                            <TableRow key={t.id} onClick={() => setActiveToken(t)}>
                                <TableCell>{t.id}</TableCell>
                                <TableCell>{t.device_alias || t.device_serial_number}</TableCell>
                                <TableCell>{t.channels_names.map(t => `${t} `)}</TableCell>
                                <TableCell>{new Date(t.expiration).toLocaleString()}</TableCell>
                                <TableCell><Link to={`/temporary-access/${t.token}`}>Go to site</Link></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div className="buttons">
                <button className="main-btn" onClick={() => setActiveToken({'id':'new'})}>New Access</button>
            </div>
            {
                activeToken.id && <TokenForm devices={devices}
                                             token={activeToken}
                                             handleCreateToken={createToken}
                                             handleEditToken={editToken}
                                             handleDeleteToken={deleteToken}
                                             setActiveToken={setActiveToken}/>
            }
        </div>
    )
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TokenForm = ({ setActiveToken, devices, handleCreateToken, token, handleEditToken, handleDeleteToken}) => {
    const [endDate, setEndDate] = useState(new Date());
    const [viewStartDate, setViewStartDate] = useState(new Date());
    const [viewEndDate, setViewEndDate] = useState(new Date());
    const [selectedType, setSelectedType] = useState('');
    const [errors, setErrors] = useState({})
    const [selectedDevice, setSelectedDevice] = useState(undefined);
    const [channels, setChannels] = useState([]);


    useEffect(() => {
        const expiration_date = token.expiration ? new Date(token.expiration) : '';
        const start_date = token.start ?  new Date(token.start ) : '';
        const end_date = token.end ?  new Date(token.end) : '';
        setEndDate(expiration_date);
        setViewStartDate(start_date);
        setViewEndDate(end_date);
        setSelectedType(token.device_id || '')
        setChannels(token.channels || [])
    }, [token])

    useEffect(() => {
        if (selectedType) {
            let selectedDevice = devices.find(d => d.id === selectedType)
            setSelectedDevice(selectedDevice);
        }
    }, [devices, selectedType])

    const checkErrors = () => {
        let errors = {}
        if (!endDate) {errors.endDate = 'required value'}
        if (!viewStartDate) {errors.viewStartDate = 'required value'}
        if (!viewEndDate) {errors.viewEndDate = 'required value'}
        if (!selectedType) {errors.selectedType = 'required value'}
        if (Object.keys(errors).length > 0) {setErrors(errors); return true}
    }

    const createToken = () => {
        if (checkErrors()) {return}
        const endDateUTC = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString();
        const viewStartDateUTC = new Date(viewStartDate.getTime() - viewStartDate.getTimezoneOffset() * 60000).toISOString();
        const viewEndDateUTC = new Date(viewEndDate.getTime() - viewEndDate.getTimezoneOffset() * 60000).toISOString();

        handleCreateToken({
            'token_expiration': endDateUTC,
            'start': viewStartDateUTC,
            'end': viewEndDateUTC,
            'device_id': selectedType,
            'enabled': true,
            'channels': channels
        })
    }

    const editToken = () => {
        if (checkErrors()) {return}
        const endDateUTC = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString();
        const viewStartDateUTC = new Date(viewStartDate.getTime() - viewStartDate.getTimezoneOffset() * 60000).toISOString();
        const viewEndDateUTC = new Date(viewEndDate.getTime() - viewEndDate.getTimezoneOffset() * 60000).toISOString();

        handleEditToken({
            'id': token.id,
            'token_expiration': endDateUTC,
            'start': viewStartDateUTC,
            'end': viewEndDateUTC,
            'device_id': selectedType,
            'enabled': true,
            'channels': channels
        })
    }

    const deleteToken = () => {
        handleDeleteToken(token.id)
    }

    const findChannel = (selectedChannels, selectedDevice) => {
        return selectedChannels.map(chan => selectedDevice.channels.find(cb => cb.id === chan)).map(c => c.alias || c.name);
    }

    return (
        <div className="dialog-wrapp">
            <div className="dialog-container access-form-container">
                <div className="section-header">
                    <div className="title">
                        {
                            token.id === 'new' ? <h2>New Temporal Access</h2> : <h2>Temporal Access {token.id}</h2>
                        }
                    </div>
                </div>
                <div className="data-header">
                    <div className="date-pickers">
                        <div className="picker-wrapp">
                            <div className="pickers">
                                <span className="date-header-label">Expiration date:</span>
                                <span>{errors.endDate}</span>
                                <DatePicker
                                    className="date-picker"
                                    selected={endDate}
                                    onChange={ (date) => {setEndDate(date)} }
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                                <img src={dateIcon} alt=""/>
                            </div>
                        </div>
                        <div className="picker-wrapp">
                            <div className="pickers">
                                <span className="date-header-label">view start date:</span>
                                <span>{errors.viewStartDate}</span>
                                <DatePicker
                                    className="date-picker"
                                    selected={viewStartDate}
                                    onChange={ (date) => {setViewStartDate(date)} }
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                                <img src={dateIcon} alt=""/>
                            </div>
                        </div>
                        <div className="picker-wrapp">
                            <div className="pickers">
                                <span className="date-header-label">view end date:</span>
                                <span>{errors.viewEndDate}</span>
                                <DatePicker
                                    className="date-picker"
                                    selected={viewEndDate}
                                    onChange={ (date) => {setViewEndDate(date)} }
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                                <img src={dateIcon} alt=""/>
                            </div>
                        </div>
                        <div>
                            <span>{errors.selectedType}</span>
                            <label className="date-header-label">Select device:</label>
                            <Select name="select-channel"
                                    className="access-select"
                                    value={selectedType}
                                    onChange={(e) => {
                                        setSelectedType(e.target.value);
                                        setChannels([]);
                                    }}>
                                {devices.map(d => {
                                    return <MenuItem value={d.id} key={d.id}>{d.alias || d.serial_number}</MenuItem>
                                })}
                            </Select>
                        </div>
                        {
                            selectedDevice ?
                                <div>
                                   <InputLabel id="demo-mutiple-checkbox-label">Select channels:</InputLabel>
                                    <Select
                                        labelId="demo-mutiple-checkbox-label"
                                        id="demo-mutiple-checkbox"
                                        multiple
                                        value={channels}
                                        onChange={e => setChannels(e.target.value)}
                                        input={<Input />}
                                        renderValue={(selected) => findChannel(selected, selectedDevice).join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {selectedDevice.channels.map((channel) => (
                                            <MenuItem key={channel.id} value={channel.id}>
                                                <Checkbox checked={channels.indexOf(channel.id) > -1} />
                                                <ListItemText primary={channel.alias || channel.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                : ""
                        }
                    </div>
                </div>
                <div className="access-buttons">
                    <button className="main-btn" onClick={() => setActiveToken({})}>
                        Cancel
                    </button>
                    {
                        token.id === 'new' ?
                            <button className="main-btn" onClick={createToken}>
                                Create
                            </button> :
                            <>
                                <button className="main-btn" onClick={editToken}>
                                    Edit
                                </button>
                                <button className="main-btn" onClick={deleteToken}>
                                    Delete
                                </button>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}

export default AccountTemporalAccess;