import React, {useEffect, useState} from "react";
import mailIcon from "../../assets/images/mail.svg";


const ClientDetails = ({ selectedClient, contacts, handleNewContact, handleEditContact, handleDeleteContact }) => {
    const [selectedContact, setSelectedContact] = useState({})

    const createContact = (contact) => {
        handleNewContact(contact);
        setSelectedContact({});
    }

    const editContact = (contact) => {
        handleEditContact(contact)
        setSelectedContact({});
    }

    const deleteContact = (contact) => {
        handleDeleteContact(contact)
        setSelectedContact({});
    }

    return (
        <div className="client-details">
            <div className="details-bottom">
                <div className="contacts">
                    <p>
                        <span className="label">Contacts</span>
                        <button className="main-btn" onClick={() => {
                            setSelectedContact({
                                'client_id':selectedClient,
                                'id': 'new',
                            })}}>New contact</button>
                    </p>
                    {
                        contacts.map(c => (
                            <div className="contact-item" key={c.id}>
                                <div className="contact-info">
                                    <div className="name">{c.name + ' ' + c.last_name}</div>
                                    <div className="mail">{c.mail}</div>
                                    <div className="btn-cnt">
                                        <button className="main-btn btn-small" onClick={() => {
                                            setSelectedContact(contacts.find(con => con.id === c.id))
                                        }}>
                                            edit
                                        </button>
                                    </div>
                                </div>
                                <a href={`mailto:${c.mail}`} className="mail-btn">
                                    <img src={mailIcon} alt=""/>
                                </a>
                            </div>
                        ))
                    }
                    {selectedContact.id && <ContactForm contact={selectedContact}
                                                        handleNewContact={createContact}
                                                        handleDeleteContact={deleteContact}
                                                        handleEditContact={editContact}
                                                        setSelectedContact={setSelectedContact}/>}
                </div>
            </div>
        </div>
    )
}

const ContactForm = ({ contact, setSelectedContact, handleNewContact, handleEditContact, handleDeleteContact }) => {
    const [name, setName] = useState('');
    const [last_name, setLastName] = useState('');
    const [mail, setEmail] = useState('');
    const [errors, setErrors] = useState({})

    useEffect(() => {
        setName(contact.name || '')
        setLastName(contact.last_name || '')
        setEmail(contact.mail || '')
    }, [contact])

    const validateNewContact = () => {
        if (validateErrors()) {return}
        handleNewContact({...contact, name, last_name, mail})
    }

    const validateEditContact = () => {
        if (validateErrors()) {return}
        handleEditContact({...contact, name, last_name, mail})
    }

    const validateErrors = () => {
        let errors = {};
        if (!name) {errors.name = "Field is required"}
        if (!last_name) {errors.last_name = "Field is required"}
        if (!mail) {errors.mail = "Field is required"}
        setErrors(errors);
        if (Object.keys(errors).length > 0) {return true}
     }

    return (
        <div className="dialog-wrapp">
            <div className="dialog-container">
                <div className="section-header">
                    <div className="title">
                        <h2>Contact: {contact.name}</h2>
                    </div>
                </div>
                <span>{errors.name}</span>
                <div className="type-select">
                    <span className="date-header-label">Name:</span>
                    <input type="text" className="textfield" value={name} onChange={e => setName(e.target.value)}/>
                </div>
                <span>{errors.last_name}</span>
                <div className="type-select">
                    <span className="date-header-label">Last Name:</span>
                    <input type="text" className="textfield" value={last_name} onChange={e => setLastName(e.target.value)}/>
                </div>
                <span>{errors.mail}</span>
                <div className="type-select">
                    <span className="date-header-label">Mail:</span>
                    <input type="text" className="textfield" value={mail} onChange={e => setEmail(e.target.value)}/>
                </div>
                <div className="buttons">
                    {
                        contact.id === 'new' ?
                            <button className="main-btn" onClick={validateNewContact}>Create</button> :
                            <>
                                <button className="main-btn" onClick={() =>
                                    handleDeleteContact(contact)}>Delete</button>
                                <button className="main-btn" onClick={validateEditContact}>Save</button>
                            </>
                    }
                    <button className="main-btn" onClick={() => setSelectedContact({})}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default ClientDetails;