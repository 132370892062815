import React, {useEffect, useState} from 'react';
import { useHistory, Switch, Route, useRouteMatch } from "react-router-dom";

import "./style.scss";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import back from "../../assets/images/down-arrow.svg";

import apiService from '../../services/request';
import ClientEdit from '../../components/ClientEdit/index';
import ClientDetails from "../../components/ClientDetails";
import CountryEdit from "../../components/CountryEdit";

const Clients = ({ user }) => {
	const [clients, setClients] = useState([]);
	const [selectedClient, setSelectedClient] = useState('');
	const [contacts, setContacts] = useState([]);
	const [countries, setCountries] = useState([]);
	const [companyErrors, setCompanyErrors] = useState({});

	useEffect( () => {
		try {
			apiService.getClients().then(clients => setClients(clients))
		} catch (e) {
			console.log(e)
		}
	}, [])

	useEffect(() => {
		try {
			apiService.getCountries().then(countries => setCountries(countries))
		} catch(e) {
			console.log(e)
		}

	}, [])

	const history = useHistory()
	const match = useRouteMatch('/clients/:id')

	useEffect(() => {
		try {
			if (match) {
				setSelectedClient(match.params.id);
			}
		} catch(e) {
			console.log(e)
		}
	}, [match])

	useEffect(() => {
		try {
			if (selectedClient) {
				apiService.getContacts(selectedClient).then(contacts => {setContacts(contacts)})
			}
		} catch(e) {
			console.log(e)
		}
	}, [selectedClient])

	const handleClientsUpdate = async (client) => {
		setCompanyErrors({})
		let errors = validateClientData(client)
		if (Object.keys(errors).length > 0) { setCompanyErrors(errors); return }
		try {
			let edited_client = await apiService.editClient(client)
			setClients(clients.map(c => c.id === edited_client.id ? edited_client : c))
		} catch(e) {
			console.log(e)
		}
	}

	const handleNewClient = async (client) => {
		setCompanyErrors({})
		let errors = validateClientData(client)
		if (Object.keys(errors).length > 0) { setCompanyErrors(errors); return  }
		try {
			let new_client = await apiService.createClient(client)
			setClients(clients.concat(new_client))
			history.push('/clients')
		} catch (e) {
			console.log(e)
		}
	}

	const handleNewCountry = async (country) => {
        try {
            let newCountry = await apiService.createCountry(country);
            setCountries(countries.concat(newCountry));
            history.push('/clients')
        } catch (e) {
            console.log(e)
        }
    };

    const handleCountryUpdate = async (country) => {
        try {
            let editedCountry = await apiService.editCountry(country);
            setCountries(countries.map(c => c.id === editedCountry.id ? editedCountry : c));
            history.push('/clients')
        } catch (e) {
            console.log(e)
        }
    }

    const handleDeleteCountry = async (country) => {
    	try {
            await apiService.deleteCountry(country);
            setCountries(countries.filter(c => c.id !== country.id ));
            history.push('/clients')
        } catch (e) {
            console.log(e)
        }
	}

	const validateClientData = (client) => {
		let errors = {}
		if (!client.name) {errors.client = "Company name is requiered"}
		if (!client.country_id || client.country_id === 'new') {
			errors.country = "please select a country"
		}
		return errors
	}

	const handleDeleteClient = async (client) => {
		try {
			let deleted_client = await apiService.deleteClient(client)
			console.log(deleted_client)
			let new_clients = clients.filter(c => c.id !== client.id)
			history.push('/clients')
			setClients(new_clients)
		} catch (e) {
			console.log(e)
		}
	}


	const handleNewContact = async (contact) => {
		try {
			let new_contact = await apiService.createContact(contact)
			setContacts(contacts.concat(new_contact))
		} catch(e) {
			console.log(e)
		}
	}

	const editContact = async (contact) => {
		try {
			let editedContact = await apiService.editContact(contact)
			setContacts(contacts.map(c => c.id === editedContact.id ? editedContact : c))
		} catch(e) {
			console.log(e)
		}
	}

	const deleteContact = async (contact) => {
		try {
			let deletedContact = await apiService.deleteContact(contact)
			setContacts(contacts.filter(c => c.id !== Number(deletedContact.id)))
		} catch(e) {
			console.log(e)
		}
	}

	const activeClient = match
		? (clients.find(c => c.id === Number(match.params.id)) || {'name':'', 'country':'', 'id':'new'})
		: {}

	return(
		<main className="main-section">
			<div className="section-header">
				<div className="title">
					{match &&
					<span className="back" onClick={() => history.push('/clients')}>
							<img src={back} alt=""/>
						</span>
					}
					<h3>Clients List</h3>
				</div>
			</div>

			<div className="clients-list-container">
				<TableContainer component={Paper} className="clients-list table-container">
					<Table stickyHeader aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Company Name</TableCell>
								<TableCell>Country</TableCell>
								<TableCell>Devices</TableCell>
								<TableCell>Timezone <br/>(ej. America/Santiago)</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{clients.map(c => (
								<TableRow key={c.id}
										  className={c.id === activeClient.id ? "active" : ""}
										  onClick={() => {history.push(`/clients/${c.id}`)}}
								>
									<TableCell>{c.name}</TableCell>
									<TableCell>{c.country}</TableCell>
									<TableCell>{c.devices}</TableCell>
									<TableCell>{c.time_difference}</TableCell>
								</TableRow>

							))}
						</TableBody>
					</Table>
				</TableContainer>

				{
					user.role === "Master Admin" ?
					<div className="buttons-top">
						<button className="main-btn" onClick={() => history.push('/clients/new')}>New Client</button>
					</div>
					: ""
				}

				{
					user.role === "Master Admin" ?
					<div>
						<h2>Countries</h2>
						<TableContainer component={Paper} className="clients-list table-container">
							<Table stickyHeader aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>Id</TableCell>
										<TableCell>Country Name</TableCell>
										<TableCell>Clients</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{countries.map(c => (
										<TableRow
											key={c.id}
											onClick={() => {history.push(`/clients/country/${c.id}`)}}
										>
											<TableCell>{c.id}</TableCell>
											<TableCell>{c.name}</TableCell>
											<TableCell>{c.total_clients}</TableCell>
										</TableRow>

									))}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
					: ""
				}

				{
					user.role === "Master Admin" ?
					<div className="buttons-top">
						<button className="main-btn" onClick={() => {
							history.push('/clients/country/new');
						}}>New Country</button>
					</div>
					: ""
				}

				<Switch>
					<Route path="/clients/country/:id">
						<CountryEdit
							handleNewCountry={handleNewCountry}
							handleCountryUpdate={handleCountryUpdate}
							handleDeleteCountry={handleDeleteCountry}
						/>
					</Route>
					<Route path="/clients/:id">
						<ClientEdit
							client={activeClient}
							logedUser={user}
							countryList={countries}
							handleClientsUpdate={handleClientsUpdate}
							handleDeleteClient={handleDeleteClient}
							handleNewClient={handleNewClient}
							errors={companyErrors}
						/>
						<ClientDetails
							selectedClient={selectedClient}
							contacts={contacts}
							handleNewContact={handleNewContact}
							handleEditContact={editContact}
							handleDeleteContact={deleteContact}
						/>
					</Route>
				</Switch>
			</div>
		</main>
	)
}

export default Clients;
