import React, { useState, useEffect } from 'react';
import "./style.scss"

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom"

import Devices from "../Devices/index.jsx";
import SingleDevice from "../SingleDevice/index.jsx";
import Clients from "../Clients/index.jsx";
import Accounts from "../Accounts/index.jsx";

import Header from "../../components/Header/index.jsx";
import LeftBar from "../../components/LeftBar/index.jsx";
import apiService from "../../services/request";


const Layout = ({ user, handleLogout }) => {
	if (!user) {return <Redirect to="/login/" />}
	return (
		<MainApp user={user} handleLogout={handleLogout}/>
	)
}

const MainApp = ({ user, handleLogout }) => {
	const [openSidebar, setOpenSidebar] = useState(false);
	const [devices, setDevices] = useState([]);
	useEffect(() => {
		try {
			apiService.getDevices().then(data => {
				data.forEach(d => {
					const timestamp = new Date(d.last_seen + 'Z');
					const nowDate = new Date();
					const nowUTCDate = new Date(nowDate.getTime() - nowDate.getTimezoneOffset() * 60000)
					d.active = nowUTCDate.getTime() - timestamp.getTime() < 600000;
				})
				setDevices(data);
			})
		} catch (e) {
			console.log(e)
		}
	}, [])

	const handldeDeviceDelete = async (device) => {
		try {
			await apiService.deleteDevice(device.id);
			const deviceList = devices.map(d => d.id !== device.id);
			setDevices(deviceList);
			window.location.replace("/");
		} catch (e) {
			console.log(e)
		}
	}

	const match = useRouteMatch('/devices/:id')
	const device = match ? devices.find(d => d.id === Number(match.params.id)) : null;

	const handleOpenSidebar = () => {setOpenSidebar(!openSidebar)}

	return(
		<div className="wrapp">
			<Header handleOpenSidebar={handleOpenSidebar} handleLogout={handleLogout} user={user}/>
			<div className="view-wrapp">
				<LeftBar openSidebar={openSidebar} handleOpenSidebar={handleOpenSidebar} user={user}/>
				<div className="view">
					<Switch>
						<Route path="/devices/:id">
							<SingleDevice device={device} user={user} handldeDeviceDelete={handldeDeviceDelete}/>
						</Route>

						<Route path="/devices">
							<Devices devices={devices}/>
						</Route>

						<Route path="/clients">
							{
								user.role !== "Client" ? <Clients user={user}/> : ""
							}
						</Route>

						<Route path="/accounts/">
							{
								user.role !== "Client" ? <Accounts devices={devices} user={user}/> : <Accounts devices={[]} user={user}/>
							}
						</Route>
						<Redirect exact from ="/" to="/devices" />
					</Switch>
				</div>
			</div>
		</div>
	)
}

export default Layout;
