import React, {useEffect, useState} from 'react';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moreIcon from "../../assets/images/down-arrow.svg";
import moment from 'moment-timezone';


const EditClient = ({ client, logedUser, countryList, handleClientsUpdate, handleDeleteClient, handleNewClient, errors }) => {
    const [clientName, setClientName] = useState('');
    const [country, setCountry] = useState('');
    const [timeDifference, setTimeDifference] = useState('');

    useEffect(() => {
        if (client) {
            setClientName(client.name)
            setTimeDifference(client.time_difference)
            if (client.id === 'new') {
                setCountry({id:"new"})
            } else {
                setCountry(countryList.find(c => c.name === client.country))
            }
        }
    }, [client, countryList])

    const timezoneList = moment.tz.names();

    const disabledFields = logedUser.role === "Client Admin";

    if (client === undefined || country === '' || country === undefined ) return <h1>Loading...</h1>;
    return (
        <div className="client-edit">
            <h2 className="company-name">{client.name}</h2>
            <div className="company-settings">
                <div className="type-select">
                    <span className="date-header-label">Company Name:</span>
                    <input type="text" className="textfield" value={clientName} onChange={(e) => { setClientName(e.target.value) }} disabled={disabledFields} />
                </div>
                <span>{errors.client}</span>

                <div className="type-select">
                    <span className="date-header-label">Country:</span>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={country.id}
                        disabled={disabledFields}
                        onChange={(e) => {
                            setCountry(countryList.find(c => c.id === e.target.value) || {'id':'new'})
                        }}
                    >
                        <MenuItem value="new">--- seleccione pais ---</MenuItem>
                        {countryList.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
                    </Select>
                    <img src={moreIcon} alt=""/>
                </div>
                <span>{errors.country}</span>

                <div className="type-select">
                    <span className="date-header-label">Timezone:</span>
                    {/* <input type="text" className="textfield" value={timeDifference} onChange={(e) => { setTimeDifference(e.target.value) }} /> */}  
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select2"
                        value={timeDifference}
                        disabled={disabledFields}
                        onChange={(e) => {
                            setTimeDifference(timezoneList.find(c => c === e.target.value) || {'id':'new'})
                        }}
                    >
                        <MenuItem value="new">--- seleccione timezone ---</MenuItem>
                        {timezoneList.map(tz => <MenuItem key={tz} value={tz}>{tz}</MenuItem>)}
                    </Select>
                </div>
                <span>{errors.client}</span>
	    
                <div className="buttons">
                    {
                        client.id === 'new' ?
                            <button className="main-btn"
                                    onClick={() => handleNewClient({name:clientName, country_id: country.id})}
                            >Create new client</button> :
                            <>
                                <button
                                    className="main-btn"
                                    onClick={() => handleClientsUpdate({...client,name: clientName, time_difference: timeDifference,country_id: country.id})}
                                >Save
                                </button>

                                {
                                    logedUser.role === "Master Admin"
                                    ? <button className="main-btn" onClick={() => handleDeleteClient(client)} >Delete</button>
                                    : ""
                                }

                            </>
                    }
                </div>
            </div>
        </div>
    )
}

export default EditClient;
