import React, {useEffect, useState} from 'react';
import { Route, Switch } from "react-router-dom";

import "./style.scss";

import Layout from "./views/Layout/index.jsx";
import GuestLayout from "./views/GuestLayout/index.jsx";
import LoginView from "./views/Login";
import loginService from './services/login';
import apiService from './services/request';

const App = () => {
	const [user, setUser] = useState(undefined);
	const [loadingDone, setLoadingDone] = useState(false);

	useEffect(() => {
		const loggedUser = window.localStorage.getItem('loggedUser')
		if (loggedUser) {
			const user = JSON.parse(loggedUser)
			setUser(user)
			apiService.setToken(user.token)
		}
		setLoadingDone(true)
	},[])

	const handleLogin = async (data) => {
		try {
			let u = await loginService.login(data)
			if (u) {
				if (u.status === 'user disabled') {return {error:'user disabled'}}
				apiService.setToken(u.token)
				window.localStorage.setItem('loggedUser', JSON.stringify(u))
				setUser(u)
			}
		} catch(e) {
			return {error: "not valid credentials"}
		}
	}

	const handleLogout = () => {
		loginService.logout(user).then(
			console.log('done')
		).finally(() => {
				apiService.setToken({})
				window.localStorage.removeItem('loggedUser')
				setUser(undefined);
			}
		).catch(e => console.log(e))
	}

	if (user) {
		apiService.testLogin().catch(err => {
			if (err.response.status === 401) {
				window.localStorage.removeItem('loggedUser')
				setUser(undefined)
			}
		})
	}

	return (
		<div>
			<Switch>
				<Route path="/temporary-access/:token" component={GuestLayout}/>
				{
					loadingDone &&
					<Route path="/login" >
						<LoginView handleLogin={handleLogin}
								   user={user}/>
					</Route>
				}
				{
					loadingDone &&
					<Route path="/">
						<Layout user={user} handleLogout={handleLogout}/>
					</Route>
				}
			</Switch>
		</div>
	);
}

export default App;
