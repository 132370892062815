import React from 'react';
import "./style.scss"

import { Link, useRouteMatch } from "react-router-dom";

import Drawer from '@material-ui/core/Drawer';

import DeviceIcon from "../../assets/images/chip.svg";
import SettingIcon from "../../assets/images/gear.svg";
import ClientsIcon from "../../assets/images/team.svg";
import DeviceActiveIcon from "../../assets/images/device-active.svg";
import ClientsActiveIcon from "../../assets/images/clients-active.svg";
import SettingActiveIcon from "../../assets/images/accounts-active.svg";

import back from "../../assets/images/down-arrow.svg";
import logo from "../../assets/images/ffqlogo.png";

const LeftBar = ({ openSidebar, handleOpenSidebar, user }) => {
	const deviceRouteMatch = useRouteMatch('/devices');
	const clientRouteMatch = useRouteMatch('/clients');
	const accountRouteMatch = useRouteMatch('/accounts');
	return(
		<Drawer className="main-menu" open={openSidebar} onClose={() => handleOpenSidebar()} >
			<ul>
				<div className="header-line">
					<div className="logo">
						<img src={logo} alt="" className="img-logo"/>
					</div>
					<div className="close" onClick={() => {handleOpenSidebar()}}>
						<img src={back} alt=""/>
					</div>
				</div>
				<Link to="/devices">
					<li className={deviceRouteMatch ? "active" : ""}>
						{deviceRouteMatch ? <img src={DeviceActiveIcon} alt=""/> : <img src={DeviceIcon} alt=""/>}
						<span>Devices</span>
					</li>
				</Link>
				{
					user.role === "Master Admin" ?
					// user.role !== "Client" ?
						<Link to="/clients">
							<li className={clientRouteMatch ? "active" : ""}>
								{clientRouteMatch ? <img src={ClientsActiveIcon} alt=""/> : <img src={ClientsIcon} alt=""/>}
								<span>Clients</span>
							</li>
						</Link>
						: ""
				}
				<Link to="/accounts">
					<li className={accountRouteMatch ? "active" : ""}>
						{accountRouteMatch ? <img src={SettingActiveIcon} alt=""/> : <img src={SettingIcon} alt=""/>}
						<span>Accounts</span>
					</li>
				</Link>
			</ul>
		</Drawer>
	)
}

export default LeftBar;
