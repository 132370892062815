import React, { useState, useEffect }  from 'react';
import { Link, NavLink, Switch, Route } from "react-router-dom";
import "./style.scss"

import DeviceData from "../../components/DeviceData/index.jsx";
import DeviceAlerts from "../../components/DeviceAlerts/index.jsx";

import AlertsConf from "../../components/AlertsConf/index.jsx";
import DeviceConf from "../../components/DeviceConf/index.jsx";
import ReportConf from "../../components/ReportConf/index.jsx";

import apiService from "../../services/request";
import back from "../../assets/images/down-arrow.svg";

const SingleDevice = ({ device, user, handldeDeviceDelete }) => {
	const [openConfig, setOpenConfig] = useState(0);
	const [deviceData, setDeviceData] = useState(null);
	const [startDate, setStartDate] = useState(( d => new Date(d.setDate(d.getDate()-1)) )(new Date()));
	const [endDate, setEndDate] = useState(new Date());
	const [clientData, setClientData] = useState(null);

	useEffect(() => {
		if (device !== undefined) {
			try {
				apiService.getDevice(device.id).then(data => {
					console.log('data', data);
					setDeviceData(data);

					apiService.getClient(data.client_id).then(clientData => {
						console.log('clientData', clientData);
						setClientData(clientData);						
					})
				})
			} catch (e) {
				console.log(e)
			}
		}
	}, [device])

	const handleDeviceDataUpdate = async (deviceData) => {
		try {
			const newDevice = await apiService.editDevice(deviceData.id, deviceData)
			setDeviceData(newDevice)
			setOpenConfig(0)
		} catch(e) {
			console.log(e)
		}
	}

	const handleAlertsUpdate = async (payload) => {
		try {
			const new_alert = await apiService.editAlert(payload.id, payload);
			const new_alerts = deviceData['alerts'].map(a => a.id === new_alert.id ? new_alert : a);
			setDeviceData({...deviceData, alerts:new_alerts})
		} catch(e) {
			console.log(e)
		}
	}

	const handleAlertDelete = async (alert_id) => {
		try {
			await apiService.deleteAlert(alert_id);
			const new_alerts = deviceData['alerts'].filter(a => a.id !== alert_id);
			setDeviceData({...deviceData, alerts:new_alerts})
		} catch(e) {
			console.log(e)
		}
	}

	const handleAlertCreate = async (payload) => {
		try {
			const new_alert = await apiService.createAlert(payload);
			const mod_alerts = deviceData['alerts'].concat(new_alert)
			setDeviceData({...deviceData, alerts:mod_alerts})
		} catch(e) {
			console.log(e)
		}
	}

	const handleNotificationAck = async (notification_id) => {
		try {
			let mod_alert = await apiService.disableAlertNotification(notification_id);
			let alerts = deviceData.alerts.map(a => a.id === mod_alert.id ? mod_alert : a)
			setDeviceData({...deviceData, alerts})
			return mod_alert
		} catch (e) {
			console.log(e)
		}
	}

	const deleteNotification = async (notification_id) => {
		try {
			let mod_alert = await apiService.deleteNotification(notification_id);
			let alerts = deviceData.alerts.map(a => a.id === mod_alert.id ? mod_alert : a)
			setDeviceData({...deviceData, alerts})
			return mod_alert
		} catch(e) {
			console.log(e)
		}
	}

	const handleDownloadDeviceData = async () => {
		try {
			const startDateUTC = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString();
			const endDateUTC = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString();
			apiService.downloadChannelData(device.id, startDateUTC, endDateUTC).then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'export_data.csv'); //or any other extension
				document.body.appendChild(link);
				link.click();
			})
		} catch (e) {
			console.log(e)
		}
	}

	if (device === undefined) {return <h1>loading...</h1>}

	return(
		<main className="main-section">
			<div className="section-header">
				<div className="title">
					<Link to="/devices/">
					<span className="back">
						<img src={back} alt=""/>
					</span>
					</Link>
					<h3>{device.alias ? device.alias : 'Field device'}
						({device.serial_number}) - {device.device_type}</h3>
				</div>
				<p className="last-seen"> Last seen {new Date(device.last_seen+ 'Z').toLocaleString("en-US", {timeZone: "UTC"})} </p>
			</div>

			<div className="tabs-header">
				<div className="tabs">
					<NavLink to={`/devices/${device.id}/data`} activeClassName={"active"} className={"tab"}>
						Data
					</NavLink>
					<NavLink to={`/devices/${device.id}/alerts`} activeClassName={"active"} className={"tab"}>
						Alerts and Config
					</NavLink>
				</div>

				<div className="buttons">
					<button className="main-btn" onClick={handleDownloadDeviceData}>Download Data</button>
					<button className="main-btn" onClick={()=>{setOpenConfig(3)}}>Create Report</button>
				</div>
			</div>

			{openConfig === 1 ?
				<div className="dialog-wrapp">
					<AlertsConf
						close={()=>{setOpenConfig(0)}}
						deviceData={deviceData}
						handleAlertsUpdate={handleAlertsUpdate}
						handleAlertsDelete={handleAlertDelete}
						handleAlertCreate={handleAlertCreate}
					/>
				</div>
				: ''}

			{openConfig === 2 ?
				<div className="dialog-wrapp">
					<DeviceConf
						close={()=>{setOpenConfig(0)}}
						device={deviceData}
						handleDeviceDataUpdate={handleDeviceDataUpdate}
						handldeDeviceDelete={handldeDeviceDelete}
					/>
				</div>
				: ''}

			{openConfig === 3 ?
				<div className="dialog-wrapp">
					<ReportConf close={()=>{setOpenConfig(0)}}
								deviceData={deviceData}
								startDate={startDate}
								setStartDate={setStartDate}
								endDate={endDate}
								setEndDate={setEndDate}
					/>
				</div>
				: ""}

			<Switch>
				<Route path="/devices/:id/data">
					<div className="tab-content">
						<div className="tab-item">
							<DeviceData
								deviceData={deviceData}
								startDate={startDate}
								setStartDate={setStartDate}
								endDate={endDate}
								setEndDate={setEndDate}
								timeDifference={clientData?.time_difference}
							/>
						</div>
					</div>
				</Route>
				<Route path="/devices/:id/alerts">
					<div className="tab-content">
						<div className="tab-item">
							<DeviceAlerts
								handleOpenConfig={(idx) => setOpenConfig(idx)}
								deviceData={deviceData}
								handleNotificationAck={handleNotificationAck}
								deleteNotification={deleteNotification}
								user={user}
								handleAlertsUpdate={handleAlertsUpdate}
								handleAlertsDelete={handleAlertDelete}
								handleAlertCreate={handleAlertCreate}
							/>
						</div>
					</div>
				</Route>
			</Switch>

		</main>
	)
}

export default SingleDevice;
