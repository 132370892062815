import React, {useEffect, useState} from 'react';
import "./style.scss"

import back from "../../assets/images/down-arrow.svg";
import apiService from "../../services/request";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const DeviceConf = ({ close, device, handleDeviceDataUpdate, handldeDeviceDelete }) => {
	const [aliasInput, setAliasInput] = useState('');
	const [companyLocation, setCompanyLocation] = useState('');
	const [channels, setChannelsData] = useState([]);
	const [clients, setClients] = useState([]);
	const [client, setClient] = useState("");

	useEffect(() => {
		if (device) {
			console.log('device', device);
			setAliasInput(device.alias || '');
			setCompanyLocation(device.location || '');
			setChannelsData(device.channels.map(d => ( {'id':d.id,
				'name': d.name || '', 'alias': d.alias || '', 'decimals': d.decimals || '', 'unit':d.unit || ''} )))
			setClient(device.client);
		}
	}, [device])

	useEffect(() => {
        try {
            apiService.getClients().then(clients => setClients(clients))
        } catch(e) {
            console.log(e)
        }
    }, [])

	const handleAliasChange = (e, chan_id) => {
		let mod_channels = channels.map(c => c.id !== chan_id ? c : {...c, alias:e.target.value})
		setChannelsData(mod_channels);
	}

	const handleDecimalsChange = (e, chan_id) => {
		let mod_channels = channels.map(c => c.id !== chan_id ? c : {...c, decimals:e.target.value})
		setChannelsData(mod_channels);
	}

	const handleUnitChange = (e, chan_id) => {
		let mod_channels = channels.map(c => c.id !== chan_id ? c : {...c, unit:e.target.value})
		setChannelsData(mod_channels);
	}

	const handleSaveClick = () => {
		const deviceData = {...device, alias:aliasInput, location: companyLocation, channels:channels, client}
		handleDeviceDataUpdate(deviceData)
	}
	const handleDeleteClick = () => {
		handldeDeviceDelete(device)
	}

	return(
		<div className="dialog-container">
			<div className="section-header">
				<div className="title">
						<span className="back" onClick={() => {close()}}>
							<img src={back} alt=""/>
						</span>
					<h3>Device config</h3>
				</div>
			</div>

			<div className="device-config-container">
				<div className="device-config">
					<div className="device-details">
						<div className="row">
							<div className="type-select">
								<span className="date-header-label">Device ID:</span>
								<span>3</span>
							</div>

							<div className="type-select">
								<span className="date-header-label">Alias:</span>
								<input type="text" className="textfield" value={aliasInput} onChange={(e)=>{setAliasInput(e.target.value)}}/>
							</div>
						</div>

						<div className="row">
							<div className="type-select">
								<span className="date-header-label">Device Type:</span>
								<span>Type 3</span>
							</div>

							<div className="type-select">
								<span className="date-header-label">Location:</span>
								<input type="text" className="textfield" value={companyLocation} onChange={(e)=>{setCompanyLocation(e.target.value)}}/>
							</div>
						</div>
						<div className="row">
                        <span className="date-header-label">Client</span>
                        <Select
                            labelId="role-select"
                            value={client}
                            className="select-role"
                            onChange={(e) => setClient(e.target.value)}
                        >
                            {clients.map(c => <MenuItem value={c.name} key={c.id}>{c.name}</MenuItem>)}
                        </Select>
                    </div>
					</div>

					<div className="device-channels">
						<div className="label">Channels</div>
						{channels.map(c => <Channel
							channel={c}
							key={c.id}
							handleAliasChange={handleAliasChange}
							handleUnitChange={handleUnitChange}
							handleDecimalsChange={handleDecimalsChange}
						/>)}
					</div>
				</div>

				<div className="buttons">
					<button className="main-btn" onClick={() => handleSaveClick()}>Save</button>
					<button className="main-btn danger-btn" onClick={() => handleDeleteClick()}>Delete Device</button>
				</div>
			</div>
		</div>
	)
}

const Channel = ({channel, handleAliasChange, handleUnitChange, handleDecimalsChange}) => (
	<div className="row">
		<div className="name">{channel.name}</div>
		<div className="type-select">
			<span className="date-header-label">Alias:</span>
			<input type="text" className="textfield" value={channel.alias} onChange={(e) => handleAliasChange(e, channel.id)}/>
		</div>
		&nbsp;
		<div className="decimal-select">
			<span className="date-header-label">Decimals:</span>
			<Select
				labelId="decimal-select"
				value={channel.decimals}
				className="select-role"
				onChange={(e) => handleDecimalsChange(e, channel.id)}
			>
				<MenuItem value="0">0</MenuItem>
				<MenuItem value="1">1</MenuItem>
				<MenuItem value="2">2</MenuItem>
			</Select>
		</div>
		&nbsp;
		<div className="type-select">
			<span className="date-header-label">Units:</span>
			<input type="text" className="textfield" value={channel.unit} onChange={(e) => handleUnitChange(e, channel.id)}/>
		</div>
	</div>
)

export default DeviceConf;
