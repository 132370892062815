import React from 'react';
import "./style.scss"

import Header from "../../components/Header/index.jsx";
import TempAccess from "../TempAccess/index.jsx";

const GuestLayout = () => {
	return(
		<div className="wrapp">
			<Header guest={true}/>
			<TempAccess/>
		</div>
	)
}

export default GuestLayout;
