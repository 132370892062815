import React, {useEffect, useState, useRef} from 'react';
import "./style.scss";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import moment from 'moment';
import moment from 'moment-timezone';

import dateIcon from "../../assets/images/calendar.svg";
import apiService from "../../services/request";

import { Line } from 'react-chartjs-2';

const DeviceData = ({deviceData, startDate, setStartDate, endDate, setEndDate, timeDifference}) => {
	const [data, setData] = useState({})
	useEffect(() => {
			const channel_colors = {
				"Ambient": "rgba(26, 188, 156,1.0)",
				"Zone 1": "rgba(52, 152, 219,1.0)",
				"Zone 2": "rgba(52, 73, 94,1.0)",
				"Zone 3": "rgba(241, 196, 15,1.0)",
				"Zone 4": "rgba(231, 76, 60,1.0)",
				"Temperature": "rgba(44, 62, 80,1.0)",
			}
			if (deviceData && endDate > startDate) {
				const startDateUTC = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString();
				const endDateUTC = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString();
				try {
					apiService.getAllChannelsData(
						deviceData.id,
						startDateUTC,
						endDateUTC,
					).then(data => {
						let graph_data = {
							datasets: []
						};
						for (let [channel, points] of Object.entries(data)) {
							const channelData = deviceData.channels.find(el => el.name === channel);
							let new_dataset = {
								label: `${channelData.alias || channelData.name} [${channelData.unit}]`,
								borderColor: channel_colors[channel],
								backgroundColor: 'rgba(255, 255, 255, 0)',
								pointBackgroundColor: channel_colors[channel],
								data: [],
								lineTension: 0,
								spanGaps: false
							}
							new_dataset.data = points.map(point => {
								const date = new Date(`${point.date}`);
//								console.log('point', point);
//								let date;
//								
//								console.log('deviceData.id == 67', deviceData.id == 67);
//								if (deviceData.id == 67) {
//									console.log('00000001');
//									date = new Date(`${point.date}Z`);
//								} else {
//									console.log('00000002');
//									date = new Date(`${point.date}`);
//								}
//
//								console.log('timeDifference && timeDifference !== null && deviceData.id == 67', timeDifference && timeDifference !== null && deviceData.id == 67);
//								if (timeDifference && timeDifference !== null && deviceData.id == 67) {
//									console.log('00000003');
//									const mDate = moment.tz(`${point.date}Z`, 'UTC');
//									console.log('mDate', mDate);
//									console.log('timeDifference', timeDifference);
//									const tzDate  = mDate.tz(timeDifference);
//									console.log('tzDate', tzDate);
//
//									date = tzDate.format();
//									console.log('date', date);
//									console.log('new date', new Date(`${date}`));
//								}


								return {
									y: Number(point.value),
									x: date
								}
							})
							graph_data.datasets.push(new_dataset);
						}
						setData(graph_data)
					})
				} catch (e) {
					console.log(e)
				}
			}
		}, [deviceData, startDate, endDate, timeDifference])

	const chartReference = useRef(null);

	const options = {
		scales: {
			xAxes: [{
				type: 'time',
				time: {
					unit: 'hour',
					displayFormats: {
						hour: 'MMM D - h:mm a'
					},
					// parser: function (date) {
					// 	return moment(date).utcOffset(timeDifference);
					// },
				}
			}],
			yAxes : [{
				ticks : {
					beginAtZero : true
				}
			}]
		}
	}

	const legendOpts = {
		onClick: (e, legendItem) => {
			var index = legendItem.datasetIndex;
			var ci = chartReference.current.chartInstance;
			var meta = ci.getDatasetMeta(index);
			meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
			ci.update();
		}
	}

	if (deviceData == null) {return <h1>loading...</h1>}

	return(
		<main className="main-section data-section">
			<div className="data-header">
				<div className="date-pickers">
					<div className="picker-wrapp">
						<div className="pickers">
							<span className="date-header-label">From:</span>
							<DatePicker
								className="date-picker"
								selected={startDate}
								onChange={ (date) => {setStartDate(date)} }
								dateFormat="MMMM d, yyyy h:mm aa"
								showTimeSelect
							/>

							<img src={dateIcon} alt=""/>
						</div>
					</div>

					<div className="picker-wrapp">
						<div className="pickers">
							<span className="date-header-label">To:</span>
							<DatePicker
								className="date-picker"
								selected={endDate}
								onChange={ (date) => {setEndDate(date)} }
								dateFormat="MMMM d, yyyy h:mm aa"
								showTimeSelect
							/>
							<img src={dateIcon} alt=""/>
						</div>
					</div>
				</div>

			</div>
			<div className="chart-container">
				<Line data={data} options={options} legend={legendOpts} ref={chartReference}/>
			</div>
		</main>
	)
}

export default DeviceData;
