import React from 'react';

import "./style.scss"

import { Link } from "react-router-dom";

import logo from "../../assets/images/ffqlogo.png";
import logOut from "../../assets/images/logout.svg";
import menu from "../../assets/images/menu.svg";

const Header = ( { handleOpenSidebar, guest, user, handleLogout } ) => {
	return(
		<header className="main-header">

			<div className="header-left">
				{!guest &&
				<div className="menu" onClick={() => handleOpenSidebar()}>
					<img src={menu} alt=""/>
				</div>}

				<Link to="/devices">
					<div className="logo">
						<img src={logo} alt=""/>
					</div>
				</Link>
			</div>

			{guest ?
			<div className="guest-label">
				Guest Access
			</div>
				:
				<div className="header-controls">
					<div className="user">
						<p className="user-name">{user.name} {user.last_name}</p>
						<p className="user-role">{user.role} {user.client}</p>
					</div>

					<div className="logout" onClick={() => handleLogout()}>
						<img src={logOut} alt=""/>
					</div>
				</div>
			}


		</header>
	)
}

export default Header;
