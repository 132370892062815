import axios from 'axios'

const baseUrl = '/api'
let token = null

const setToken = newToken => {
    token = `Bearer ${newToken}`
}

const testLogin = () => {
    const config = {
        headers: {Authorization: token}
    }
    const request = axios.get(`${baseUrl}/check_session`, config)
    return request.then(resp => resp.data)
}

const getDevices = () => {
    const config = {
        headers: {Authorization: token}
    }
    const request = axios.get(`${baseUrl}/devices`, config)
    return request.then(resp => resp.data)
}

const getClient = (id) => {
    const config = {
        headers: {Authorization: token}
    }
    const request = axios.get(`${baseUrl}/clients/${id}`, config)
    return request.then(resp => resp.data)
}

const deleteDevice = (device_id) => {
    const config = {
        headers: {Authorization: token}
    }
    const request = axios.delete(`${baseUrl}/devices/${device_id}`, config)
    return request.then(resp => resp.data)
}

const getClients = () => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/clients`
    const request = axios.get(url, config)
    return request.then(resp => resp.data);
}

const createClient = async(client) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/clients`
    const response = await axios.post(url, client, config)
    return response.data;
}

const editClient = async (client) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/clients/${client.id}`
    const response = await axios.put(url, client, config)
    return response.data;
}

const deleteClient = async (client) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/clients/${client.id}`
    const response = await axios.delete(url, config)
    return response.data;
}

const getContacts = (client_id) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/clients/${client_id}/contacts`
    const request = axios.get(url, config)
    return request.then(resp => resp.data)
}

const createContact = async (contact) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/clients/${contact.client_id}/contacts`
    const response = await axios.post(url, contact, config)
    return response.data
}

const editContact = async (contact) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/clients/${contact.client_id}/contacts/${contact.id}`
    const response = await axios.put(url, contact, config)
    return response.data
}

const deleteContact = async (contact) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/clients/${contact.client_id}/contacts/${contact.id}`
    const response = await axios.delete(url, contact, config)
    return response.data
}


const getCountries = () => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/country_list`
    const request = axios.get(url, config)
    return request.then(resp => resp.data);
}

const createCountry = async (country) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/country/new`
    const response = await axios.post(url, country, config)
    return response.data
}

const editCountry = async (country) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/country/${country.id}`
    const response = await axios.put(url, country, config)
    return response.data
}

const deleteCountry = async (country) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/country/${country.id}`
    const response = await axios.delete(url,config)
    return response.data
}



const getDevice = (id) => {
    const config = {
        headers: {Authorization: token}
    }
    const request = axios.get(`${baseUrl}/devices/${id}`, config)
    return request.then(resp => resp.data)
}

const getChannelData = (id, from, to) => {
    const config = {
        headers: {Authorization: token}
    }
    const request = axios.get(`${baseUrl}/channels/${id}/data?from=${from}&to=${to}`, config)
    return request.then(resp => resp.data)
}

const getAllChannelsData = (device_id, from, to) => {
    const config = {
        headers: {Authorization: token}
    }
    const request = axios.get(`${baseUrl}/devices/${device_id}/channel_data?from=${from}&to=${to}`, config)
    return request.then(resp => resp.data)
}

const getReportChannelsData = (device_id, from, to, channels) => {
    const config = {
        headers: {Authorization: token, channels: JSON.stringify(channels)},
    }
    const request = axios.get(`${baseUrl}/devices/${device_id}/channel_filter?from=${from}&to=${to}`, config)
    return request.then(resp => resp.data)
}

const downloadChannelData = (device_id, from, to) => {
    const config = {
        headers: {Authorization: token},
        responseType: 'blob'
    }
    const request = axios.get(`${baseUrl}/devices/${device_id}/channel_data/download?from=${from}&to=${to}`, config)
    return request.then(resp => resp);
}

const editDevice = async (id, channel)  => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/devices/${id}`;
    const response = await axios.post(url, channel, config)
    return response.data
}

const getAlerts = async (channel_id) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/${channel_id}/alerts`
    const response = await axios.get(url, config)
    return response.data;
}

const editAlert = async (alert_id, alert) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/alerts/${alert_id}`
    const response = await axios.put(url, alert, config)
    return response.data;
}

const deleteAlert = async(alert_id) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/alerts/${alert_id}`
    const response = await axios.delete(url, config)
    return response.data;
}

const createAlert = async(payload) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/alerts/${payload.device_id}`
    const response = await axios.post(url, payload, config)
    return response.data;
}

const disableAlertNotification = async (notification_id) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/notifications/${notification_id}/acknowledge`
    const response = await axios.post(url, {}, config)
    return response.data;
}

const deleteNotification = async (notification_id) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/notifications/${notification_id}`
    const response = await axios.delete(url, config)
    return response.data;
}

const getUsers = () => {
    const config = {
        headers: {Authorization: token}
    }
    const request = axios.get(`${baseUrl}/users`, config)
    return request.then(resp => resp.data)
}

const getRoles = () => {
    const config = {
        headers: {Authorization: token}
    }
    const request = axios.get(`${baseUrl}/roles`, config)
    return request.then(resp => resp.data)
}

const createUser = async (user) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/users`
    const response = await axios.post(url, user, config)
    return response.data;
}

const editUser = async (user) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/users/${user.id}`
    const response = await axios.put(url, user, config)
    return response.data;
}

const deleteUser = async (user) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/users/${user.id}`
    const response = await axios.delete(url, config)
    return response.data;
}

const getTemporalAccess = () => {
    const config = {
        headers: {Authorization: token}
    }
    const request = axios.get(`${baseUrl}/temporaltoken`, config)
    return request.then(resp => resp.data)
}

const createTemporalAccess = async (data) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/temporaltoken`
    const response = await axios.post(url, data, config)
    return response.data;
}

const editTemporalAccess = async (data) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/temporaltoken/${data.id}`
    const response = await axios.put(url, data, config)
    return response.data;
}

const deleteTemporalAccess = async (token_id) => {
    const config = {
        headers: {Authorization: token}
    }
    let url = `${baseUrl}/temporaltoken/${token_id}`
    const response = await axios.delete(url, config)
    return response.data;
}

const getTokenData = (token) => {
    try {
        const request = axios.get(`${baseUrl}/tokenaccess/${token}`)
        return request.then(resp => resp.data)
    } catch (e) {
        return e;
    }
}


const getTokenChannelData = (id, from, to, token) => {
    const request = axios.get(`${baseUrl}/tokenaccess/${token}/${id}?from=${from}&to=${to}`)
    return request.then(resp => resp.data)
}

const getAllChannelsDataWithToken = (from, to, token) => {
    try {
        const request = axios.get(`${baseUrl}/tokenaccess/${token}/graph_data?from=${from}&to=${to}`)
        return request.then(resp => resp.data)
    } catch (e) {
        return e;
    }
}


const getDashboardData = () => {
    const config = {
        headers: {Authorization: token}
    }
    const request = axios.get(`${baseUrl}/dashboard_filters`, config)
    return request.then(resp => resp.data)
}


export default {
    getDashboardData,
    getTokenChannelData,
    getTokenData,
    setToken,
    getAllChannelsDataWithToken,
    getDevices,
    getDevice,
    deleteDevice,
    getChannelData,
    getAllChannelsData,
    getReportChannelsData,
    editDevice,
    getAlerts ,
    editAlert,
    deleteAlert,
    disableAlertNotification,
    deleteNotification,
    downloadChannelData,
    createAlert,
    getClients,
    getClient,
    editClient,
    getCountries,
    deleteClient,
    createClient,
    createCountry,
    editCountry,
    deleteCountry,
    getContacts,
    createContact,
    editContact,
    deleteContact,
    getUsers,
    getRoles,
    createUser,
    editUser,
    deleteUser,
    testLogin,
    getTemporalAccess,
    createTemporalAccess,
    editTemporalAccess,
    deleteTemporalAccess
}
