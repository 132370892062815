import React, {useEffect, useState} from "react";
import "./style.scss"
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export const AlertView = ({ alert, deviceData, channels, setActive, handleAlertsUpdate, handleAlertsDelete, handleAlertCreate, user }) => {
	const [name, setName] = useState('');
	const [sValue, setSValue] = useState('');
	const [aboveSValue, setAboveSValue] = useState(true);
	const [rangeType, setRangeType] = useState(false);
	const [disconnectType, setDisconnectType] = useState(false);
	const [maxValue, setMaxValue] = useState('');
	const [minValue, setMinValue] = useState('');
	const [recipients, setRecipients]= useState([]);
	const [newRecipient, setNewRecipient] = useState('')
	const [selectedChannel, setSelectedChannel] = useState([]);
	const [errors, setErrors] = useState({});
	const [isOwnMail, setIsOwnMail] = useState(false);
	const [alarmTypeDisplay, setAlarmTypeDisplay] = useState("");

	useEffect(() => {
		setName(alert.name);
		setRecipients(alert.recipients || []);
		setIsOwnMail(alert.recipients.includes(user.mail));
		setSelectedChannel(alert.channels || [])
		if (alert.disconnect) {
			setDisconnectType(true);
			setSValue(alert.set_value);
			setAlarmTypeDisplay("disconnect");
		} else if (alert.range_value) {
			setRangeType(true);
			setMinValue(alert.min_value);
			setMaxValue(alert.max_value);
			setAlarmTypeDisplay("range");
		} else {
			setSValue(alert.set_value);
			setAboveSValue(alert.above_set_value);
			if (alert.above_set_value) {
				setAlarmTypeDisplay("more-than")
			} else {
				setAlarmTypeDisplay("less-than")
			}
		}
	}, [alert, user])

	const check_errors = () => {
		let errors = {};
		if (name === '') {
			errors['name'] = "name cannot be empty"
		}
		if (!disconnectType) {
			if (rangeType) {
				if (minValue === "") {
					errors['min_value'] = "min value cannot be empty"
				}
				if (maxValue === "") {
					errors['max_value'] = "max value cannot be empty"
				}
			} else {
				if (sValue === "") {
					errors['value'] = "set value cannot be empty"
				}
			}
		} else {
			if (sValue < 10) {
				errors['value'] = "min value is 10"
			}
			if (sValue > 2000) {
				errors['value'] = "max value is 2000"
			}

		}
		if (selectedChannel.length === 0) {
			errors['selectedChannel'] = "Please select at least a channel"
		}
		setErrors(errors)
		return Object.keys(errors).length > 0
	}

	const sendAlert = async () => {
		if (check_errors()) return;
		try {
			const payload = {
				'id': alert.id,
				'device_id': deviceData.id,
				'name': name,
				'set_value': sValue,
				'recipients': recipients,
				'above_set_value': aboveSValue,
				'min_value': minValue,
				'max_value': maxValue,
				'range_value': rangeType,
				'disconnect': disconnectType,
				'channels': selectedChannel
			}
			handleAlertsUpdate(payload)
			setActive({})
		} catch(e) {
			console.log(e)
		}
	}

	const createAlert = async () => {
		if (check_errors()) return;
		if (selectedChannel === '') return;
		try {
			const payload = {
				'name': name,
				'device_id': deviceData.id,
				'set_value': sValue,
				'recipients': recipients,
				'above_set_value': aboveSValue,
				'channels': selectedChannel,
				'min_value': minValue,
				'max_value': maxValue,
				'range_value': rangeType,
				'disconnect': disconnectType
			}
			handleAlertCreate(payload)
			setActive({})
		} catch(e) {
			console.log(e)
		}
	}

	const sendDelete = async () => {
		try {
			handleAlertsDelete(alert.id)
			setActive({})
		} catch(e) {
			console.log(e)
		}
	}

	const setAlarmType = (alarm_type) => {
		if (alarm_type === "less-than") {
			setAboveSValue(false);
			setRangeType(false);
		} else if (alarm_type === "more-than") {
			setAboveSValue(true);
			setRangeType(false);
		} else if (alarm_type === "range") {
			setAboveSValue(false);
			setRangeType(true);
		} else if (alarm_type === "disconnect") {
			setDisconnectType(true);
			setRangeType(false);
			setAboveSValue(false);
		}
		setAlarmTypeDisplay(alarm_type);
	}

	const addRecipient = () => {
		if (newRecipient && validateEmail(newRecipient)) {
			recipients.push(newRecipient)
			setNewRecipient('')
		}
	}

	const addSelfRecipient = () => {
		recipients.push(user.mail)
		setIsOwnMail(true);
	}

	const removeSelf = () => {
		setRecipients(recipients.filter(r => r !== user.mail));
		setIsOwnMail(false);
	}

	const removeRecipient = (idx) => {
		let mod_recipients = recipients.concat([])
		mod_recipients.splice(idx, 1)
		setRecipients(mod_recipients)
	}

	const alarm_types = <div className="type-select">
		<label className="date-header-label">Alarm Type:</label>
		<select name="select-channel" onChange={(e) => setAlarmType(e.target.value)} value={alarmTypeDisplay}>
			<option value="less-than">Less than</option>
			<option value="more-than">More Than</option>
			<option value="range">Range</option>
			<option value="disconnect">Disconnect</option>
		</select>
	</div>;

	const alarm_types_vars = () => {
		// if (disconnectType) {
		// 	return <div className="type-select">&nbsp;</div>
		// }

		return rangeType
			? <div className="type-select">
				<span className="date-header-label">min value:</span>
				<input
					type="text" className="textfield"
					value={minValue}
					onChange={(e)=>{setMinValue(e.target.value)}}
				/>
				<span className="date-header-label">max value:</span>
				<input
					type="text" className="textfield"
					value={maxValue}
					onChange={(e)=>{setMaxValue(e.target.value)}}
				/>
			</div>

			: ( disconnectType ?
				<div className="type-select">
					<span className="date-header-label">set value (in minuts. Min: 10, Max: 2000)</span>
					<input
						type="number" className="textfield"
						min={10} max={2000}
						value={sValue}
						onChange={(e)=>{setSValue(e.target.value)}}
					/>
				</div>

				: <div className="type-select">
					<span className="date-header-label">set value:</span>
					<input
						type="text" className="textfield"
						value={sValue}
						onChange={(e)=>{setSValue(e.target.value)}}
					/>
				</div>
			)
	}

	const validateEmail = (email) => {
		// eslint-disable-next-line
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	const addItselfToAlarm = isOwnMail ? "": <div className="recipients-container type-select">
		<span className="date-header-label">recipients:</span>
		add your email? {user.mail}
		<button onClick={() => addSelfRecipient()}>add</button>
	</div> ;

	const showOwnMail = isOwnMail ? <div className="row mail-cnt">
											<p className="date-header-label">{user.mail}</p>
											<button onClick={() => removeSelf()}>remove</button>
										</div> : "";

	return (
		<div className="alerts-set-table alert-detail">
			<div className="section-header">
				<div className="title">
					<h3>{alert.name}</h3>
				</div>
			</div>
			<div className="device-config-container">
				<div className="device-config">
					<div className="device-details">
						<div className="row">
							<div className="type-select">
								<span className="date-header-label">Name:</span>
								<input
									type="text" className="textfield"
									value={name}
									onChange={(e)=>{setName(e.target.value)}}
								/>
							</div>

						</div>
						<div>
							{
								user.role !== "Client" ?
								<div className="recipients-container type-select">
									<span className="date-header-label">recipients:</span>
									<input
										type="text" className="textfield"
										value={newRecipient}
										onChange={(e)=>{setNewRecipient(e.target.value)}}
									/>
									<button onClick={() => addRecipient()}>add</button>
								</div> :
									addItselfToAlarm
							}
							<div className="type-select recipients-list">
								{user.role !== "Client" ? recipients.map((r, idx) => {
									return (
										<div key={idx} className="row mail-cnt">
											<p className="date-header-label">{r}</p>
											<button onClick={() => removeRecipient(idx)}>remove</button>
										</div>
									)
								}): showOwnMail}
							</div>
						</div>
						{errors['name'] && <div className="row"><p>{errors['name']}</p></div>}
						<div className="row">
							{alarm_types_vars()}

							{alarm_types}
						</div>
						{errors['value'] && <div className="row"><p>{errors['value']}</p></div>}
						{errors['min_value'] && <div className="row"><p>{errors['min_value']}</p></div>}
						{errors['max_value'] && <div className="row"><p>{errors['max_value']}</p></div>}
						<div className="row">
							<div className="type-select">
								<InputLabel id="demo-mutiple-checkbox-label">Select channels:</InputLabel>
                                    <Select
                                        labelId="demo-mutiple-checkbox-label"
                                        id="demo-mutiple-checkbox"
                                        multiple
                                        value={selectedChannel}
                                        onChange={e => setSelectedChannel(e.target.value)}
                                        input={<Input />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {channels.map((channel) => (
                                            <MenuItem key={channel.name} value={channel.name}>
                                                <Checkbox checked={selectedChannel.indexOf(channel.name) > -1} />
                                                <ListItemText primary={channel.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
								{errors['selectedChannel'] && <div className="row"><p>{errors['selectedChannel']}</p></div>}
							</div>
						</div>
					</div>
				</div>
				{
					alert.id === 'new' ?
						<div className="buttons">
							<button className="main-btn" onClick={createAlert}>Create</button>
							<button className="main-btn" onClick={() => setActive({})}>Cancel</button>
						</div> :
						<div className="buttons">
							<button className="main-btn" onClick={sendAlert}>Save</button>
							<button className="main-btn" onClick={() => setActive({})}>Cancel</button>
							<button className="main-btn" onClick={sendDelete}>Delete</button>
						</div>
				}
			</div>
		</div>
	)
}
