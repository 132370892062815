import React, {useEffect, useState} from 'react';
import "./style.scss"

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import back from "../../assets/images/down-arrow.svg";

const AlertsConf = ({close, deviceData, handleAlertsUpdate, handleAlertsDelete, handleAlertCreate }) => {
	const [active, setActive] = useState({});

	return(
		<div className="dialog-container">
			<div className="section-header">
				<div className="title">
						<span className="back" onClick={() => {close()}}>
							<img src={back} alt=""/>
						</span>
					<h3>Alerts set</h3>
				</div>
			</div>
			{active.id ?
				<AlertModal
					alert={active}
					channels={deviceData['channels']}
					setActive={setActive}
					handleAlertsUpdate={handleAlertsUpdate}
					handleAlertsDelete={handleAlertsDelete}
					handleAlertCreate={handleAlertCreate}
				/>:
				<AlertTable alerts={deviceData['alerts']} setActive={setActive} active={active} />
			}
		</div>
	)
}


const AlertModal = ({ alert, channels, setActive, handleAlertsUpdate, handleAlertsDelete, handleAlertCreate }) => {
	const [name, setName] = useState('');
	const [sValue, setSValue] = useState('');
	const [aboveSValue, setAboveSValue] = useState(true);
	const [rangeType, setRangeType] = useState(false);
	const [disconnectType, setDisconnectType] = useState(false);
	const [maxValue, setMaxValue] = useState('');
	const [minValue, setMinValue] = useState('');
	const [recipients, setRecipients]= useState([]);
	const [newRecipient, setNewRecipient] = useState('')
	const [selectedChannel, setSelectedChannel] = useState('');
	const [errors, setErrors] = useState({});
	const [alarmTypeDisplay, setAlarmTypeDisplay] = useState("");

	useEffect(() => {
		setName(alert.name);
		setRecipients(alert.recipients || []);
		if (alert.disconnect) {
			setDisconnectType(true);
			setSValue(alert.set_value);
			setAlarmTypeDisplay("disconnect");
		} else if (alert.range_value) {
			setRangeType(true);
			setMinValue(alert.min_value);
			setMaxValue(alert.max_value);
		} else {
			setSValue(alert.set_value);
			setAboveSValue(alert.above_set_value);
		}
	}, [alert])

	const check_errors = () => {
		let errors = {};
		if (name === '') {
			errors['name'] = "name cannot be empty"
		}
		if (!disconnectType) {
			if (rangeType) {
				if (minValue === "") {
					errors['min_value'] = "min value cannot be empty"
				}
				if (maxValue === "") {
					errors['max_value'] = "max value cannot be empty"
				}
			} else {
				if (sValue === "") {
					errors['value'] = "set value cannot be empty"
				}
			}
		} else {
			if (sValue < 10) {
				errors['value'] = "min value is 10"
			}
			if (maxValue > 2000) {
				errors['value'] = "max value is 2000"
			}

		}
		setErrors(errors)
		return Object.keys(errors).length > 0
	}

	const sendAlert = async () => {
		if (check_errors()) return;
		try {
			const payload = {
				'id': alert.id,
				'name': name,
				'set_value': sValue,
				'recipients': recipients,
				'above_set_value': aboveSValue,
				'min_value': minValue,
				'max_value': maxValue,
				'range_value': rangeType
			}
			handleAlertsUpdate(payload)
			setActive({})
		} catch(e) {
			console.log(e)
		}
	}

	const createAlert = async () => {
		if (check_errors()) return;
		if (selectedChannel === '') return;
		try {
			const payload = {
				'name': name,
				'set_value': sValue,
				'recipients': recipients,
				'above_set_value': aboveSValue,
				'channel_id': selectedChannel,
				'min_value': minValue,
				'max_value': maxValue,
				'range_value': rangeType
			}
			handleAlertCreate(payload)
			setActive({})
		} catch(e) {
			console.log(e)
		}
	}

	const sendDelete = async () => {
		try {
			handleAlertsDelete(alert.id)
			setActive({})
		} catch(e) {
			console.log(e)
		}
	}

	const setAlarmType = (alarm_type) => {
		if (alarm_type === "less-than") {
			setAboveSValue(false);
			setRangeType(false);
		} else if (alarm_type === "more-than") {
			setAboveSValue(true);
			setRangeType(false);
		} else if (alarm_type === "range") {
			setAboveSValue(false);
			setRangeType(true);
		}
	}

	const addRecipient = () => {
		if (newRecipient && validateEmail(newRecipient)) {
			recipients.push(newRecipient)
			setNewRecipient('')
		}
	}

	const removeRecipient = (idx) => {
		let mod_recipients = recipients.concat([])
		mod_recipients.splice(idx, 1)
		setRecipients(mod_recipients)
	}

	const alarm_types = <div className="type-select">
		<label className="date-header-label">Alarm Type:</label>
		<select name="select-channel" onChange={(e) => setAlarmType(e.target.value)}>
			<option value="less-than">Less than</option>
			<option value="more-than">More Than</option>
			<option value="range">Range</option>
		</select>
	</div>;

	const validateEmail = (email) => {
		// eslint-disable-next-line
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	return (
		<div className="alerts-set-table">
			<div className="section-header">
				<div className="title">
					<h3>Alert - {alert.id}</h3>
				</div>
			</div>
			<div className="device-config-container">
				<div className="device-config">
					<div className="device-details">
						<div className="row">
							<div className="type-select">
								<span className="date-header-label">Name:</span>
								<input
									type="text" className="textfield"
									value={name}
									onChange={(e)=>{setName(e.target.value)}}
								/>
							</div>

						</div>
						<div>
							<div className="recipients-container type-select">
								<span className="date-header-label">recipients:</span>
								<input
									type="text" className="textfield"
									value={newRecipient}
									onChange={(e)=>{setNewRecipient(e.target.value)}}
								/>
								<button onClick={() => addRecipient()}>add</button>
							</div>
							<div className="type-select recipients-list">
								{recipients.map((r, idx) => {
									return (
										<div key={r} className="row mail-cnt"><p className="date-header-label">{r}</p><button onClick={() => removeRecipient(idx)}>remove</button></div>
									)
								})}
							</div>
						</div>
						{errors['name'] && <div className="row"><p>{errors['name']}</p></div>}
						<div className="row">
							{
								rangeType
									?
									<div className="type-select">
										<span className="date-header-label">min value:</span>
										<input
											type="text" className="textfield"
											value={minValue}
											onChange={(e)=>{setMinValue(e.target.value)}}
										/>
										<span className="date-header-label">max value:</span>
										<input
											type="text" className="textfield"
											value={maxValue}
											onChange={(e)=>{setMaxValue(e.target.value)}}
										/>
									</div>

									: ( disconnectType ?
										<div className="type-select">
											<span className="date-header-label">set value (in minuts. Min: 10, Max: 2000):</span>
											<input
												type="number" className="textfield"
												min={10} max={2000}
												value={sValue}
												onChange={(e)=>{setSValue(e.target.value)}}
											/>
										</div>

										: <div className="type-select">
											<span className="date-header-label">set value:</span>
											<input
												type="text" className="textfield"
												value={sValue}
												onChange={(e)=>{setSValue(e.target.value)}}
											/>
										</div>
									)
								}


							{alarm_types}
						</div>
						{errors['value'] && <div className="row"><p>{errors['value']}</p></div>}
						{errors['min_value'] && <div className="row"><p>{errors['min_value']}</p></div>}
						{errors['max_value'] && <div className="row"><p>{errors['max_value']}</p></div>}
						{alert.id === 'new' &&
						<div className="row">
							<div className="type-select">
								<label className="date-header-label">select channel:</label>
								<select name="select-channel" onChange={(e) => setSelectedChannel(e.target.value)}>
									<option value="">--select a channel--</option>
									{
										channels.map(c => (
											<option value={c.id} key={c.id}>{c.alias || c.name}</option>
										))
									}
								</select>
							</div>
						</div>
						}
					</div>
				</div>
				{
					alert.id === 'new' ?
						<div className="buttons">
							<button className="main-btn" onClick={createAlert}>Create</button>
							<button className="main-btn" onClick={() => setActive({})}>Cancel</button>
						</div> :
						<div className="buttons">
							<button className="main-btn" onClick={sendAlert}>Save</button>
							<button className="main-btn" onClick={() => setActive({})}>Cancel</button>
							<button className="main-btn" onClick={sendDelete}>Delete</button>
						</div>
				}
			</div>
		</div>
	)
}

const AlertTable = ({alerts, setActive, active}) => {
	return (
		<div className="alerts-set-table">
			<TableContainer component={Paper} className="clients-list table-container">
				<Table stickyHeader aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Alert Name</TableCell>
							<TableCell>Channel</TableCell>
							<TableCell>Value</TableCell>
							<TableCell>Recipients</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{alerts.map(a => (
							<TableRow key={a.id} onClick={()=>{setActive(a)}} className={a.id === active.id ? "active" : ""}>
								<TableCell>{a.name}</TableCell>
								<TableCell>{a.channel_id}</TableCell>
								<TableCell>{a.range_value ? `${a.min_value} - ${a.max_value}`: a.set_value}</TableCell>
								<TableCell>{a.recipients.length}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<div className="buttons">
				<button className="main-btn" onClick={() => setActive({
					'id':"new",
					'name': '',
					'set_value': '',
					'recipients': '',
					'above_set_value': false
				})}>
					New
				</button>
			</div>
		</div>
	)
}

export default AlertsConf;
