import React from 'react';
import "./style.scss"

import back from "../../assets/images/down-arrow.svg";

import AccessTable from "../../components/AccessTable";
import AccountTemporalAccess from "../../components/AccountTable";

const Accounts = ({ devices, user }) => {
	return(
		<main className="main-section">
			<div className="section-header">
				<div className="title">
						<span className="back">
							<img src={back} alt=""/>
						</span>
					<h3>Accounts</h3>
				</div>
			</div>

			<div className="accounts-list-container">
				<AccessTable user={user}/>
				{user.role === "Master Admin" ? <AccountTemporalAccess devices={ devices } /> : ""}
			</div>
		</main>
	)
}

export default Accounts;
