import React, {useEffect, useState} from 'react';
import { useRouteMatch } from 'react-router-dom';
import "./style.scss"

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Line } from 'react-chartjs-2';

import dateIcon from "../../assets/images/calendar.svg";
import apiService from '../../services/request'

const options = {
		scales: {
			xAxes: [{
				type: 'time',
				time: {
					unit: 'hour',
					displayFormats: {
						hour: 'MMM D - h:mm a'
					}
				}
			}]
		}
	}

const TempAccess = () => {
	const [deviceData, setDeviceData] = useState({});
	const [invalidToken, setInvalidToken] = useState(false);
	const [startDate, setStartDate] = useState(( d => new Date(d.setDate(d.getDate()-1)) )(new Date()));
	const [endDate, setEndDate] = useState(new Date());
	const [data, setData] = useState({})

	const match = useRouteMatch('/temporary-access/:token')
	const token = match ? match.params.token : '';

	useEffect(() => {
		if (!token) {return}
		apiService.getTokenData(token)
			.then(data => {
				if (data) {
					setDeviceData(data);
					setInvalidToken(false);
				}
			})
			.catch(() => setInvalidToken(true))
	}, [token])

	useEffect(() => {
			if (!deviceData.id || invalidToken) {
				return;
			}
			const channel_colors = {
				"Ambient": "rgba(26, 188, 156,1.0)",
				"Zone 1": "rgba(52, 152, 219,1.0)",
				"Zone 2": "rgba(52, 73, 94,1.0)",
				"Zone 3": "rgba(241, 196, 15,1.0)",
				"Zone 4": "rgba(231, 76, 60,1.0)",
				"Temperature": "rgba(44, 62, 80,1.0)"
			}
			if (token && endDate > startDate) {
				const startDateUTC = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString();
				const endDateUTC = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString();
				try {
					apiService.getAllChannelsDataWithToken(
						startDateUTC,
						endDateUTC,
						token
					).then(data => {
						let graph_data = {
							datasets: []
						};
						for (let [channel, points] of Object.entries(data)) {
							const channelData = deviceData.channels.find(el => el.name === channel);
							let new_dataset = {
								label: `${channelData.alias || channelData.name} [${channelData.unit}]`,
								borderColor: channel_colors[channel],
								backgroundColor: 'rgba(255, 255, 255, 0)',
								data: []
							}
							new_dataset.data = points.map(point => {
								const date = new Date(`${point.date}`);
								return {
									y: Number(point.value),
									x: date
								}
							})
							graph_data.datasets.push(new_dataset);
						}
						setData(graph_data)
					})
				} catch (e) {
					console.log(e)
				}
			}
		}, [token, startDate, endDate, invalidToken, deviceData])

	if (!token) {return <h1>-- invalid URL --</h1>}
	if (invalidToken) {return <main className="main-section temp-access"><h1>Invalid token</h1></main>}
	if (!deviceData.id) {return <h1>loading...</h1>}

	return(
		<main className="main-section temp-access">
			<div className="section-header">
				<div className="title">
					<h3>Field device {deviceData.serial_number} - Type {deviceData.device_type}</h3>
				</div>

				<div className="date-range">
					<div className="picker-wrapp">
						<div className="pickers">
							<span className="date-header-label">From:</span>
							<DatePicker
								className="date-picker"
								selected={startDate}
								onChange={ (date) => {setStartDate(date)} }
								dateFormat="MMMM d, yyyy h:mm aa"
							/>

							<img src={dateIcon} alt=""/>
						</div>
					</div>

					<div className="picker-wrapp">
						<div className="pickers">
							<span className="date-header-label">To:</span>
							<DatePicker
								className="date-picker"
								selected={endDate}
								onChange={ (date) => {setEndDate(date)} }
								dateFormat="MMMM d, yyyy h:mm aa"
							/>

							<img src={dateIcon} alt=""/>
						</div>
					</div>
				</div>
			</div>

			<div className="temp-access-content">
				<div className="chart">
					<div className="chart-container">
						{
							data.datasets && data.datasets.length > 0 && <Line data={data} options={options}/>
						}
					</div>
				</div>
				{data.labels && data.labels.length > 0 &&
				<div className="table">
					<div className="label">Data by timestamps</div>
					<TableContainer component={Paper} className="clients-list table-container">
						<Table stickyHeader aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Timestamp</TableCell>
									<TableCell>Value</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.labels.map((el, idx) => {
									return (
										<TableRow key={idx}>
											<TableCell>{el}</TableCell>
											<TableCell>{data.datasets[0].data[idx]}</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
				}
			</div>
		</main>
	)
}

export default TempAccess;
