import React, {useEffect, useState} from 'react';
import "./style.scss"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import back from "../../assets/images/down-arrow.svg";
import dateIcon from "../../assets/images/calendar.svg";
import DeviceReport from '../../components/DeviceReport/index';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ReportConf = ({close, deviceData, startDate, setStartDate, endDate, setEndDate}) => {
	const [technician, setTechnician] = useState('')
	const [location, setLocation] = useState('');
	const [customer, setCustomer] = useState('');
	const [facility, setFacility] = useState('');
	const [treatment, setTreatment] = useState('');
	const [notes, setNotes] = useState('');
	const [data, setData] = useState(undefined)
	const [error, setError] = useState({});
	const [channels, setChannels] = useState([]);

	useEffect(() => {
		setChannels(deviceData["channels"].map(c => c.name))
	}, [deviceData])

	const handleCreateReport = () => {
		let error = {}
		if (!technician) {error.technician = 'please include a value'}
		if (!location) {error.location = 'please include a value'}
		if (!customer) {error.customer = 'please include a value'}
		if (!facility) {error.facility = 'please include a value'}
		if (!treatment) {error.treatment = 'please include a value'}

		if (Object.keys(error).length > 0) {setError(error); return}

		setData({
			startDate,
			endDate,
			technician,
			location,
			customer,
			facility,
			treatment,
			notes,
			channels
		})
	}

	const handleCloseReport = () => {
		setData(undefined)
	}

	if (data) {return <DeviceReport formData={data}
									deviceData={deviceData}
									closeReport={handleCloseReport}/>
	}

	return(
		<div className="dialog-container">
			<div className="section-header">
				<div className="title">
						<span className="back" onClick={() => {close()}}>
							<img src={back} alt=""/>
						</span>
					<h3>Create report</h3>
				</div>
			</div>

			<div className="report-config-container">
				<div className="report-create">

					<div className="date-range">
						<div className="label">Set time range</div>

						<div className="row">
							<div className="picker-wrapp">
								<div className="pickers">
									<span className="date-header-label">From:</span>
									<DatePicker
										className="date-picker"
										selected={startDate}
										onChange={(date) => {
											setStartDate(date)
										}}
										dateFormat="MMMM d, yyyy h:mm aa"
									/>

									<img src={dateIcon} alt=""/>
								</div>
							</div>

							<div className="picker-wrapp">
								<div className="pickers">
									<span className="date-header-label">To:</span>
									<DatePicker
										className="date-picker"
										selected={endDate}
										onChange={(date) => {
											setEndDate(date)
										}}
										dateFormat="MMMM d, yyyy h:mm aa"
									/>

									<img src={dateIcon} alt=""/>
								</div>
							</div>
						</div>
					</div>

					<div>
						<InputLabel id="demo-mutiple-checkbox-label">Select channels:</InputLabel>
						<Select
							labelId="demo-mutiple-checkbox-label"
							id="demo-mutiple-checkbox"
							multiple
							value={channels}
							onChange={e => setChannels(e.target.value)}
							input={<Input />}
							renderValue={(selected) => selected.join(', ')}
							MenuProps={MenuProps}
						>
							{deviceData.channels.map((channel) => (
								<MenuItem key={channel.name} value={channel.name}>
									<Checkbox checked={channels.indexOf(channel.name) > -1} />
									<ListItemText primary={channel.name} />
								</MenuItem>
							))}
						</Select>
					</div>

					<div className="label">Report Information</div>
					<div className="report-options">
						<div className="column">
							<div className="type-select">
								<span className="date-header-label">Technician:</span>
								<input type="text" className="textfield" value={technician} onChange={(e)=>{setTechnician(e.target.value)}}/>
							</div>
							<span>{error.technician}</span>

							<div className="type-select">
								<span className="date-header-label">Customer:</span>
								<input type="text" className="textfield" value={customer} onChange={(e)=>{setCustomer(e.target.value)}}/>
							</div>
							<span>{error.customer}</span>

							<div className="type-select">
								<span className="date-header-label">Treatment ID:</span>
								<input type="text" className="textfield" value={treatment} onChange={(e)=>{setTreatment(e.target.value)}}/>
							</div>
							<span>{error.treatment}</span>
						</div>

						<div className="column">
							<div className="type-select">
								<span className="date-header-label">Location:</span>
								<input type="text" className="textfield" value={location} onChange={(e)=>{setLocation(e.target.value)}}/>
							</div>
							<span>{error.location}</span>

							<div className="type-select">
								<span className="date-header-label">Facility:</span>
								<input type="text" className="textfield" value={facility} onChange={(e)=>{setFacility(e.target.value)}}/>
							</div>
							<span>{error.facility}</span>
						</div>

						<div className="row">
							<div className="type-select">
								<span className="date-header-label">Notes:</span>
								<textarea className="textfield"
										  onChange={(e)=>{setNotes(e.target.value)}}
										  value={notes} />
							</div>
						</div>
					</div>
				</div>

				<div className="buttons">
					<button className="main-btn" onClick={handleCreateReport}>Create</button>
				</div>
			</div>
		</div>
	)
}

export default ReportConf;
