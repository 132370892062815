import React, {useEffect, useState} from 'react';
import {useRouteMatch} from "react-router-dom";
import apiService from "../../services/request";

const CountryEdit = ({ handleNewCountry, handleCountryUpdate, handleDeleteCountry }) => {
    const [countries, setCountries] = useState([]);
    const [countryName, setCountryName] = useState('');
    const [countryId, setCountryId] = useState('');
    const [formCountryName, setFormCountryName] = useState('');
    const [clientsNumber, setClientsNumber] = useState(0);
    const [errors, setErrors] = useState({});
    const countryMatch = useRouteMatch('/clients/country/:id');

    useEffect(() => {
		try {
			apiService.getCountries().then(countries => setCountries(countries))
		} catch(e) {
			console.log(e)
		}
	}, [])


    useEffect(() => {
        if (countryMatch.params.id === "new") {
            setCountryName("new country")
            //setFormCountryName("new country name");
        } else if (countries.length > 0) {
            const country = countries.find(c => c.id === Number(countryMatch.params.id));
            setCountryName(country.name);
            setCountryId(country.id);
            setFormCountryName(country.name);
            setClientsNumber(country.total_clients)
        }
    }, [countries, countryMatch.params.id])

    const deleteButton = clientsNumber ? "" :<button
                                    className="main-btn"
                                    onClick={() => handleDeleteCountry({id:countryId})}
                                >Delete</button>;

    return (
        <div className="client-edit">
            <h2 className="company-name">{countryName}</h2>
            <div className="company-settings">
                <div className="type-select">
                    <span className="date-header-label">Country Name:</span>
                    <input type="text" className="textfield" value={formCountryName}
                           onChange={(e) => {
                               setFormCountryName(e.target.value)
                           }}/>
                </div>
                <span>{errors.client}</span>
                <div className="buttons">
                    {
                        countryMatch.params.id === "new" ?
                            <button className="main-btn"
                                    onClick={() => handleNewCountry({name:formCountryName})}
                            >Create</button> :
                            <>
                                <button
                                    className="main-btn"
                                    onClick={() => handleCountryUpdate({name:formCountryName, id:countryId})}
                                >Save</button>
                                {deleteButton}
                            </>
                    }
                </div>
            </div>
        </div>
    )
};

export default CountryEdit;